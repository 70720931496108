import { useRoutes, Outlet, Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Dashboard from '../pages/admin/dashboard';
import MainLayout from '../components/admin/mainLayout/MainLayout';
import OrderMainLayout from '../components/order/mainLayout/MainLayout';
import Error404Page from '../pages/common/Error404Page';
import Invite from '../pages/questions/invite';
import Home from '../pages/questions/home';
import Login from '../components/admin/auth/login';
import Templates from '../pages/admin/templates';
import TemplateForm from '../pages/admin/templates/TemplateForm';
import Users from '../pages/admin/users';
import AddEditUser from '../pages/admin/users/AddEditUser';
import Clients from '../pages/admin/clients';
import AddEditClient from '../pages/admin/clients/AddEditClient';
import Admins from '../pages/admin/admins';
import AddEditAdmin from '../pages/admin/admins/AddEditAdmin';
import Sources from '../pages/admin/sources';
import AddEditSources from '../pages/admin/sources/AddEditSources';
import OrderList from '../pages/order/orderList';
import ViewFormDetails from '../pages/admin/participantForms/ViewFormDetails';
import ViewFormDetailsUpdated from '../pages/admin/participantForms/ViewFormDetailsUpdated';
import UserProfile from '../pages/common/UserProfile';
import ChangePassword from '../pages/common/ChangePassword';
import AddOrder from '../pages/order/addOrder';
import ResetPassword from '../pages/common/ResetPassword';
import ViewClient from '../pages/admin/clients/ViewClient';
import ViewUser from '../pages/admin/users/ViewUser';
import ViewAdmin from '../pages/admin/admins/ViewAdmin';
import ViewSource from '../pages/admin/sources/ViewSource';
import ForgotPassword from '../pages/common/ForgotPassword';
import ResetForgotPassword from '../pages/common/ResetForgotPassword';
import OptAuth from '../pages/admin/admins/OptAuth';
import Configurations from '../pages/admin/configurations';
import Offices from '../pages/admin/clients/Offices';
import Office from '../pages/admin/clients/Office';
import AuditTrails from '../pages/admin/auditTrails';
import DocumentConfigurations from '../pages/admin/configurations/DocumentConfigurations';
import Reports from '../pages/admin/reports';
import MobySuccess from '../pages/common/MobySuccess';
import BulkUpload from '../pages/order/addOrder/BulkUpload';
import OrderDetailsUpdated from '../pages/order/orderDetail/OrderDetailsUpdated';
import ImportedOrderList from '../pages/order/orderList/ImportedOrderList';
import IntegrationLogs from '../pages/admin/integrationLogs';
import OrderTypes from '../pages/admin/configurations/OrderTypes';

const Routes = () => {
  const routes = useRoutes([
    {
      element: <Outlet />,
      children: [
        {
          path: '/',
          element: <Navigate to='/admin/dashboard' />,
        },
        { path: 'participant/auth/:code', element: <OptAuth /> },
        { path: 'participant/dl/confirmation/:code', element: <MobySuccess /> },
        {
          path: '/reset-password',
          element: <ResetPassword />,
        },
        {
          path: '/forgot-password',
          children: [
            { path: '', element: <ForgotPassword /> },
            {
              path: 'reset/:token',
              element: <ResetForgotPassword />,
            },
          ],
        },
        {
          path: '/questions',
          children: [
            { path: 'invite/:code', element: <Invite /> },
            { path: 'invite/:code/:participantNo', element: <Invite /> },
            {
              path: 'home/:code',
              element: <PrivateRoute component={<Home />} type='participant' />,
            },
            {
              path: 'home/:code/:participantNo',
              element: <PrivateRoute component={<Home />} type='participant' />,
            },
          ],
        },
        { path: 'login', element: <Login /> },
        { path: '/:office/login', element: <Login /> },
        { path: '/offices', element: <Offices /> },
        { path: '/:office', element: <Office /> },
        {
          path: '/admin',
          // element: <Navigate to={'/admin/templates'} />,
          children: [
            { path: '', element: <Navigate to={'/admin/dashboard'} /> },
            {
              path: 'audit-trails',
              element: (
                <PrivateRoute
                  component={
                    <MainLayout>
                      <AuditTrails />
                    </MainLayout>
                  }
                  type='admin'
                  breadcrumb={[
                    {
                      title: 'Audit Trails',
                      url: '/admin/audit-trails',
                    },
                  ]}
                />
              ),
            },
            {
              path: 'clients/:user_id/:id',
              element: (
                <PrivateRoute
                  component={
                    <MainLayout>
                      <AddEditClient />
                    </MainLayout>
                  }
                  type='admin'
                  breadcrumb={[
                    {
                      title: ':id',
                      url: '/admin/:user_id/:id',
                    },
                  ]}
                />
              ),
            },
            {
              path: 'configurations',
              element: (
                <PrivateRoute
                  component={
                    <MainLayout>
                      <Configurations />
                    </MainLayout>
                  }
                  type='admin'
                  clientLevelAccess={true}
                  breadcrumb={[
                    {
                      title: 'Preferences',
                      url: '/admin/configurations',
                    },
                  ]}
                />
              ),
            },
            {
              path: 'order-types',
              element: (
                <PrivateRoute
                  component={
                    <MainLayout>
                      <OrderTypes />
                    </MainLayout>
                  }
                  type='admin'
                  clientLevelAccess={true}
                  breadcrumb={[
                    {
                      title: 'Order Types',
                      url: '/admin/order-types',
                    },
                  ]}
                />
              ),
            },
            {
              path: 'document-configurations',
              element: (
                <PrivateRoute
                  component={
                    <MainLayout>
                      <DocumentConfigurations />
                    </MainLayout>
                  }
                  type='admin'
                  breadcrumb={[
                    {
                      title: 'Document Configurations',
                      url: '/admin/document-configurations',
                    },
                  ]}
                />
              ),
            },
            {
              path: 'dashboard',
              element: (
                <PrivateRoute
                  component={
                    <MainLayout>
                      <Dashboard />
                    </MainLayout>
                  }
                  type='admin'
                  breadcrumb={[
                    {
                      title: 'Dashboard',
                      url: '/admin/dashboard',
                    },
                  ]}
                />
              ),
            },
            {
              path: 'reports',
              element: (
                <PrivateRoute
                  type='admin'
                  component={
                    <MainLayout>
                      <Reports />
                    </MainLayout>
                  }
                  breadcrumb={[
                    {
                      title: 'Reports',
                      url: '/admin/reports',
                    },
                  ]}
                />
              ),
            },
            {
              path: 'integration-logs',
              element: (
                <PrivateRoute
                  onlySuperAdminAccess={true}
                  type='admin'
                  component={
                    <MainLayout>
                      <IntegrationLogs />
                    </MainLayout>
                  }
                  breadcrumb={[
                    {
                      title: 'Integration Logs',
                      url: '/admin/integration-logs',
                    },
                  ]}
                />
              ),
            },
            {
              path: 'templates',
              children: [
                {
                  path: '',
                  element: (
                    <PrivateRoute
                      component={
                        <MainLayout>
                          <Templates />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Form Configurations',
                          url: '/admin/templates',
                        },
                      ]}
                    />
                  ),
                },
                {
                  path: ':id',
                  element: (
                    <PrivateRoute
                      component={
                        <MainLayout>
                          <TemplateForm />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Form Configurations',
                          url: '/admin/templates',
                        },
                        {
                          title: ':id',
                          url: '/admin/templates/:id',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              path: 'users',
              children: [
                {
                  path: '',
                  element: (
                    <PrivateRoute
                      component={
                        <MainLayout>
                          <Users />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Users',
                          url: '/admin/users',
                        },
                      ]}
                    />
                  ),
                },
                {
                  path: 'add',
                  element: (
                    <PrivateRoute
                      component={
                        <MainLayout>
                          <AddEditUser />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Users',
                          url: '/admin/users',
                        },
                        {
                          title: 'Add User',
                          url: '/orders/user/addUser',
                        },
                      ]}
                    />
                  ),
                },
                {
                  path: ':id',
                  element: (
                    <PrivateRoute
                      component={
                        <MainLayout>
                          <AddEditUser />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Users',
                          url: '/admin/users',
                        },
                        {
                          title: ':id',
                          url: '/admin/users/:id',
                        },
                      ]}
                    />
                  ),
                },
                {
                  path: ':id/view',
                  element: (
                    <PrivateRoute
                      component={
                        <MainLayout>
                          <ViewUser />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Users',
                          url: '/admin/users',
                        },
                        {
                          title: 'view',
                          url: '/admin/users/:id/view',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              path: 'clients',
              children: [
                {
                  path: '',
                  element: (
                    <PrivateRoute
                      onlyAdminAccess={true}
                      component={
                        <MainLayout>
                          <Clients />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Client Offices',
                          url: '/admin/clients',
                        },
                      ]}
                    />
                  ),
                },
                {
                  path: 'add',
                  element: (
                    <PrivateRoute
                      onlyAdminAccess={true}
                      component={
                        <MainLayout>
                          <AddEditClient />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Client Offices',
                          url: '/admin/clients',
                        },
                        {
                          title: 'Add Client',
                          url: '/admin/clients/add',
                        },
                      ]}
                    />
                  ),
                },
                {
                  path: ':id',
                  element: (
                    <PrivateRoute
                      onlyAdminAccess={true}
                      component={
                        <MainLayout>
                          <AddEditClient />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Client Offices',
                          url: '/admin/clients',
                        },
                        {
                          title: ':id',
                          url: '/admin/clients/:id',
                        },
                      ]}
                    />
                  ),
                },
                {
                  path: ':id/view',
                  element: (
                    <PrivateRoute
                      onlyAdminAccess={true}
                      component={
                        <MainLayout>
                          <ViewClient />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Client Offices',
                          url: '/admin/clients',
                        },
                        {
                          title: 'view',
                          url: '/admin/clients/:id',
                        },
                      ]}
                    />
                  ),
                },
                {
                  path: 'templates/:clientId',
                  element: (
                    <PrivateRoute
                      component={
                        <MainLayout>
                          <Templates />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Clients',
                          url: '/admin/clients',
                        },
                        {
                          title: 'Form Configurations',
                          url: '/admin/clients/templates',
                        },
                      ]}
                    />
                  ),
                },
                {
                  path: 'order-types/:client_id',
                  element: (
                    <PrivateRoute
                      component={
                        <MainLayout>
                          <OrderTypes />
                        </MainLayout>
                      }
                      type='admin'
                      clientLevelAccess={true}
                      breadcrumb={[
                        {
                          title: 'Order Types',
                          url: '/admin/order-types',
                        },
                      ]}
                    />
                  ),
                },
                {
                  path: 'document-configurations/:client_id',
                  element: (
                    <PrivateRoute
                      component={
                        <MainLayout>
                          <DocumentConfigurations />
                        </MainLayout>
                      }
                      type='admin'
                      clientLevelAccess={true}
                      breadcrumb={[
                        {
                          title: 'Document Configurations',
                          url: '/admin/document-configurations',
                        },
                      ]}
                    />
                  ),
                },
                {
                  path: 'templates/:clientId/:id',
                  element: (
                    <PrivateRoute
                      component={
                        <MainLayout>
                          <TemplateForm />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Clients',
                          url: '/admin/clients',
                        },
                        {
                          title: 'Form Configurations',
                          url: '/admin/clients/templates/:clientId',
                        },
                        {
                          title: 'Template',
                          url: '/admin/clients/templates/:clientId/:id',
                        },
                      ]}
                    />
                  ),
                },
                {
                  path: 'configurations/:client_id',
                  element: (
                    <PrivateRoute
                      component={
                        <MainLayout>
                          <Configurations />
                        </MainLayout>
                      }
                      type='admin'
                      clientLevelAccess={true}
                      breadcrumb={[
                        {
                          title: 'Clients',
                          url: '/admin/clients',
                        },
                        {
                          title: 'Client Preference',
                          url: '/admin/configurations',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              path: 'list',
              children: [
                {
                  path: '',
                  element: (
                    <PrivateRoute
                      onlySuperAdminAccess={true}
                      component={
                        <MainLayout>
                          <Admins />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Admins',
                          url: '/admin/list',
                        },
                      ]}
                    />
                  ),
                },
                {
                  path: 'add',
                  element: (
                    <PrivateRoute
                      onlySuperAdminAccess={true}
                      component={
                        <MainLayout>
                          <AddEditAdmin />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Admins',
                          url: '/admin/list',
                        },
                        {
                          title: 'Add Admin',
                          url: '/admin/list/add',
                        },
                      ]}
                    />
                  ),
                },
                {
                  path: ':id',
                  element: (
                    <PrivateRoute
                      onlySuperAdminAccess={true}
                      component={
                        <MainLayout>
                          <AddEditAdmin />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Admins',
                          url: '/admin/list',
                        },
                        {
                          title: ':id',
                          url: '/admin/admin/:id',
                        },
                      ]}
                    />
                  ),
                },
                {
                  path: ':id/view',
                  element: (
                    <PrivateRoute
                      onlySuperAdminAccess={true}
                      component={
                        <MainLayout>
                          <ViewAdmin />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Admins',
                          url: '/admin/list',
                        },
                        {
                          title: 'view',
                          url: '/admin/admin/:id/view',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              path: 'sources',
              children: [
                {
                  path: '',
                  element: (
                    <PrivateRoute
                      component={
                        <MainLayout>
                          <Sources />
                        </MainLayout>
                      }
                      type='admin'
                      onlyAdminAccess={true}
                      breadcrumb={[
                        {
                          title: 'Source Mapping',
                          url: '/admin/sources',
                        },
                      ]}
                    />
                  ),
                },
                {
                  path: 'add',
                  element: (
                    <PrivateRoute
                      onlyAdminAccess={true}
                      component={
                        <MainLayout>
                          <AddEditSources />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Source Mapping',
                          url: '/admin/sources',
                        },
                        {
                          title: 'Add',
                          url: '/orders/sources/add',
                        },
                      ]}
                    />
                  ),
                },
                {
                  path: ':id',
                  element: (
                    <PrivateRoute
                      onlyAdminAccess={true}
                      component={
                        <MainLayout>
                          <AddEditSources />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Source Mapping',
                          url: '/admin/sources',
                        },
                        {
                          title: ':id',
                          url: '/admin/sources/:id',
                        },
                      ]}
                    />
                  ),
                },
                {
                  path: ':id/view',

                  element: (
                    <PrivateRoute
                      onlyAdminAccess={true}
                      component={
                        <MainLayout>
                          <ViewSource />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Source Mapping',
                          url: '/admin/sources',
                        },
                        {
                          title: 'view',
                          url: '/admin/sources/:id/view',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              path: 'imported-orders',
              children: [
                {
                  path: '',
                  element: (
                    <PrivateRoute
                      component={
                        <MainLayout>
                          <ImportedOrderList />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Imported Orders',
                          url: '/admin/imported-orders',
                        },
                      ]}
                    />
                  ),
                },

                {
                  path: 'bulk-upload',
                  element: (
                    <PrivateRoute
                      component={
                        <MainLayout>
                          <BulkUpload />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Imported Orders',
                          url: '/admin/imported-orders',
                        },
                        {
                          title: 'Bulk Order Upload',
                          url: '/admin/imported-orders/bulk-upload',
                        },
                      ]}
                    />
                  ),
                },

                {
                  path: ':id/edit',
                  element: (
                    <PrivateRoute
                      component={
                        <MainLayout>
                          <AddOrder />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Imported Orders',
                          url: '/admin/imported-orders',
                        },
                        {
                          title: 'Edit Order',
                          url: '/admin/imported-orders/:id/edit',
                        },
                      ]}
                    />
                  ),
                },
                {
                  path: ':id',
                  element: (
                    <PrivateRoute
                      component={
                        <MainLayout>
                          <OrderDetailsUpdated />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Imported Orders',
                          url: '/admin/imported-orders',
                        },
                        {
                          title: 'Order Details',
                          url: '/admin/imported-orders/:id',
                        },
                      ]}
                    />
                  ),
                },
                {
                  path: ':id/:participantId',
                  element: (
                    <PrivateRoute
                      component={
                        <MainLayout>
                          <ViewFormDetailsUpdated />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Imported Orders',
                          url: '/admin/imported-orders',
                        },
                        {
                          title: 'Order Participants',
                          url: '/admin/imported-orders/:id',
                        },
                        {
                          title: 'Participant Form Details',
                          url: '/admin/imported-orders/:participantId',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              path: 'orders',
              children: [
                {
                  path: '',
                  element: (
                    <PrivateRoute
                      component={
                        <MainLayout>
                          <OrderList />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Orders',
                          url: '/admin/orders',
                        },
                      ]}
                    />
                  ),
                },

                {
                  path: 'bulk-upload',
                  element: (
                    <PrivateRoute
                      component={
                        <MainLayout>
                          <BulkUpload />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Orders',
                          url: '/admin/orders',
                        },
                        {
                          title: 'Bulk Order Upload',
                          url: '/admin/orders/bulk-upload',
                        },
                      ]}
                    />
                  ),
                },
                {
                  path: 'add',
                  element: (
                    <PrivateRoute
                      component={
                        <MainLayout>
                          <AddOrder />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Orders',
                          url: '/admin/orders',
                        },
                        {
                          title: 'Add Order',
                          url: '/admin/orders/add',
                        },
                      ]}
                    />
                  ),
                },
                {
                  path: ':id/edit',
                  element: (
                    <PrivateRoute
                      component={
                        <MainLayout>
                          <AddOrder />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Orders',
                          url: '/admin/orders',
                        },
                        {
                          title: 'Edit Order',
                          url: '/admin/orders/:id/edit',
                        },
                      ]}
                    />
                  ),
                },
                {
                  path: ':id',
                  element: (
                    <PrivateRoute
                      component={
                        <MainLayout>
                          <OrderDetailsUpdated />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Orders',
                          url: '/admin/orders',
                        },
                        {
                          title: 'Order Details',
                          url: '/admin/orders/:id',
                        },
                      ]}
                    />
                  ),
                },
                {
                  path: ':id/:participantId',
                  element: (
                    <PrivateRoute
                      component={
                        <MainLayout>
                          <ViewFormDetailsUpdated />
                        </MainLayout>
                      }
                      type='admin'
                      breadcrumb={[
                        {
                          title: 'Orders',
                          url: '/admin/orders',
                        },
                        {
                          title: 'Order Participants',
                          url: '/admin/orders/:id',
                        },
                        {
                          title: 'Participant Form Details',
                          url: '/admin/orders/:participantId',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            {
              path: 'profile',
              element: (
                <PrivateRoute
                  component={
                    <MainLayout>
                      <UserProfile />
                    </MainLayout>
                  }
                  type='admin'
                  breadcrumb={[
                    {
                      title: 'Profile',
                      url: 'profile',
                    },
                  ]}
                />
              ),
            },
            {
              path: 'ChangePassword',
              element: (
                <PrivateRoute
                  component={
                    <MainLayout>
                      <ChangePassword />
                    </MainLayout>
                  }
                  type='admin'
                  breadcrumb={[
                    {
                      title: 'Change Password',
                      url: 'ChangePassword',
                    },
                  ]}
                />
              ),
            },
            { path: '*', element: <Error404Page /> },
          ],
        },
        {
          path: '/orders',
          children: [
            {
              path: 'imported-orders',
              children: [
                {
                  path: '',
                  element: (
                    <PrivateRoute
                      onlyUserAccess={true}
                      component={
                        <OrderMainLayout>
                          <ImportedOrderList />
                        </OrderMainLayout>
                      }
                      type='order'
                      breadcrumb={[
                        {
                          title: 'Imported Orders',
                          url: '/Orders/imported-orders',
                        },
                      ]}
                    />
                  ),
                },
                {
                  path: ':id',
                  element: (
                    <PrivateRoute
                      onlyUserAccess={true}
                      component={
                        <OrderMainLayout>
                          <OrderDetailsUpdated />
                        </OrderMainLayout>
                      }
                      type='order'
                      breadcrumb={[
                        {
                          title: 'Imported Orders',
                          url: '/orders/imported-orders',
                        },
                        {
                          title: 'Order Details',
                          url: '/orders/imported-orders/:id',
                        },
                      ]}
                    />
                  ),
                },
                {
                  path: ':id/:participantId',
                  element: (
                    <PrivateRoute
                      component={
                        <OrderMainLayout>
                          <ViewFormDetailsUpdated />
                        </OrderMainLayout>
                      }
                      type='order'
                      onlyUserAccess={true}
                      breadcrumb={[
                        {
                          title: 'Imported Orders',
                          url: '/orders/imported-orders',
                        },
                        {
                          title: 'Order Details',
                          url: '/orders/imported-orders/:id',
                        },
                        {
                          title: 'Participant Form Details',
                          url: '/orders/imported-orders/:id/:participantId',
                        },
                      ]}
                    />
                  ),
                },
                {
                  path: 'bulk-upload',
                  element: (
                    <PrivateRoute
                      component={
                        <OrderMainLayout>
                          <BulkUpload />
                        </OrderMainLayout>
                      }
                      type='order'
                      onlyUserAccess={true}
                      breadcrumb={[
                        {
                          title: 'Imported Orders',
                          url: '/orders/imported-orders',
                        },
                        {
                          title: 'Bulk Order Upload',
                          url: '/orders/imported-orders/bulk-upload',
                        },
                      ]}
                    />
                  ),
                },
                {
                  path: 'add',
                  element: (
                    <PrivateRoute
                      onlyUserAccess={true}
                      component={
                        <OrderMainLayout>
                          <AddOrder />
                        </OrderMainLayout>
                      }
                      type='order'
                      breadcrumb={[
                        {
                          title: 'Imported Orders',
                          url: '/orders/imported-orders',
                        },
                        {
                          title: 'Create Order',
                          url: '/orders/imported-orders/add',
                        },
                      ]}
                    />
                  ),
                },
              ],
            },
            { path: '', element: <Navigate to={'/orders/order'} /> },
            {
              path: 'reports',
              element: (
                <PrivateRoute
                  component={
                    <OrderMainLayout>
                      <Reports />
                    </OrderMainLayout>
                  }
                  type='order'
                  breadcrumb={[
                    {
                      title: 'Reports',
                      url: '/orders/reports',
                    },
                  ]}
                />
              ),
            },
            {
              path: 'order',
              element: (
                <PrivateRoute
                  onlyUserAccess={true}
                  component={
                    <OrderMainLayout>
                      <OrderList />
                    </OrderMainLayout>
                  }
                  type='order'
                  breadcrumb={[
                    {
                      title: 'Orders',
                      url: '/orders/order',
                    },
                  ]}
                />
              ),
            },
            {
              path: ':id',
              element: (
                <PrivateRoute
                  onlyUserAccess={true}
                  component={
                    <OrderMainLayout>
                      <OrderDetailsUpdated />
                    </OrderMainLayout>
                  }
                  type='order'
                  breadcrumb={[
                    {
                      title: 'Orders',
                      url: '/orders/order',
                    },
                    {
                      title: 'Order Details',
                      url: '/orders/:id',
                    },
                  ]}
                />
              ),
            },
            {
              path: ':id/:participantId',
              element: (
                <PrivateRoute
                  component={
                    <OrderMainLayout>
                      <ViewFormDetailsUpdated />
                    </OrderMainLayout>
                  }
                  type='order'
                  onlyUserAccess={true}
                  breadcrumb={[
                    {
                      title: 'Orders',
                      url: '/orders/order',
                    },
                    {
                      title: 'Order Details',
                      url: '/orders/:id',
                    },
                    {
                      title: 'Participant Form Details',
                      url: '/orders/:id/:participantId',
                    },
                  ]}
                />
              ),
            },
            {
              path: 'bulk-upload',
              element: (
                <PrivateRoute
                  component={
                    <OrderMainLayout>
                      <BulkUpload />
                    </OrderMainLayout>
                  }
                  type='order'
                  onlyUserAccess={true}
                  breadcrumb={[
                    {
                      title: 'Orders',
                      url: '/orders',
                    },
                    {
                      title: 'Bulk Order Upload',
                      url: '/orders/bulk-upload',
                    },
                  ]}
                />
              ),
            },
            {
              path: 'add',
              element: (
                <PrivateRoute
                  onlyUserAccess={true}
                  component={
                    <OrderMainLayout>
                      <AddOrder />
                    </OrderMainLayout>
                  }
                  type='order'
                  breadcrumb={[
                    {
                      title: 'Orders',
                      url: '/orders/order',
                    },
                    {
                      title: 'Create Order',
                      url: '/orders/add',
                    },
                  ]}
                />
              ),
            },
            {
              path: 'profile',
              element: (
                <PrivateRoute
                  component={
                    <OrderMainLayout>
                      <UserProfile />
                    </OrderMainLayout>
                  }
                  type='order'
                  breadcrumb={[
                    {
                      title: 'Profile',
                      url: 'profile',
                    },
                  ]}
                />
              ),
            },
            {
              path: 'ChangePassword',
              element: (
                <PrivateRoute
                  component={
                    <OrderMainLayout>
                      <ChangePassword />
                    </OrderMainLayout>
                  }
                  type='order'
                  breadcrumb={[
                    {
                      title: 'Change Password',
                      url: 'ChangePassword',
                    },
                  ]}
                />
              ),
            },
            { path: '*', element: <Error404Page /> },
          ],
        },

        { path: '*', element: <Error404Page /> },
      ],
    },
  ]);
  return routes;
};

export default Routes;
