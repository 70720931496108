import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { Grid, TextField, Tooltip } from '@mui/material';
import useRedux from '../../../../utils/hooks/useRedux';
import { areAllElementsSame, errorChecker } from '../../../../utils/helpers.js';
import { useSelector } from 'react-redux';
import { getSSN } from '../../../../services/order/order.js';
import { SSN_FIELDS } from '../../../../utils/constants.js';
import { getFullSNN } from '../../../../services/questions/home.js';
import { Help } from '@mui/icons-material';

const SocialNumber = ({ element, disabled }) => {
  const { code } = useParams();
  const selectedLanguage = useSelector((state) => state.questionSlice.languages)?.[code];
  const languageForLabel = selectedLanguage && selectedLanguage !== 'en' ? selectedLanguage : '';
  const {
    setFormValue,
    getFormValue,
    getErrorDetails,
    removeError,
    setError,
    setTouched,
    findIsTouched,
  } = useRedux();
  const authData = useSelector((state) => state?.authSlice)?.[code];
  const order_part_participant_id = useSelector(
    (state) => state.questionSlice.questionDetails
  )?.find((que) => que.tx_id === code)?.order_part_participant_id;
  const translations = useSelector((state) => state.questionSlice.convertedTexts)?.find(
    (que) => que.tx_id === code
  );
  const formValue = getFormValue(element.element_id);
  const { error, error_message } = getErrorDetails(element.element_id) || {
    error: false,
    error_message: '',
  };
  const [value, setValue] = useState('');
  const [placeholders, setPlaceholders] = useState([]);
  const [disabledField, setDisabledField] = useState(false);

  // useEffect(() => {
  //   let tempPlaceholders = [];
  //   if (element?.verification?.verification_source?.length > 0) {
  //     for (let source of element?.verification?.verification_source) {
  //       for (let source_value of source.value) {
  //         if (tempPlaceholders.indexOf(source_value) < 0) {
  //           tempPlaceholders.push(source_value);
  //         }
  //       }
  //     }
  //   }
  //   if (tempPlaceholders?.length) {
  //     if (
  //       (tempPlaceholders?.length === 1 || areAllElementsSame(tempPlaceholders)) &&
  //       !element.user_value
  //     ) {
  //       setFormValue({ id: element?.element_id, value: tempPlaceholders[0] });
  //       setValue(tempPlaceholders[0]);
  //       // setDisabledField(true);
  //     }
  //   }
  //   setPlaceholders(tempPlaceholders);
  // }, [element?.element_id, element?.verification?.verification_source]);

  useEffect(() => {
    if (formValue !== undefined) setValue(formValue);
    else if (element.user_value) setValue(element.user_value);
    else if (SSN_FIELDS.find((field) => element?.element_name?.startsWith(field))) getFullSSN();
  }, []);

  const getFullSSN = async () => {
    const isAdditionalParticipant =
      (element?.participant_number && element?.participant_number !== 1) ||
      element.element_name === 'core_spouse_ssn' ||
      element.element_name === 'borrower_spouse_ssn'
        ? true
        : false;
    let payload = { show_number: true };
    if (isAdditionalParticipant) {
      if (!element?.ssn_part_1?.length || !element?.ssn_part_2?.length) return;
      payload.ssn_part_1 = element?.ssn_part_1;
      payload.ssn_part_2 = element?.ssn_part_2;
    } else {
      payload.order_part_participant_id = order_part_participant_id;
      payload.participant_no = 1;
    }
    const res = await getSSN(payload, authData?.token);
    if (res.success && res?.response?.full_number) {
      setFormValue({ id: element?.element_id, value: res?.response?.full_number });
      setValue(res?.response?.full_number);
    }
  };

  const handleChange = (event) => {
    setTouched(element.element_id);
    setValue(event.target.value);
    setFormValue({ id: element.element_id, value: event.target.value });
  };

  let placeholder = '';
  if (element.verification.verification_source.length > 0)
    placeholder = element.verification.verification_source[0].value[0];

  const handleError = () => {
    const { isError, message } = errorChecker(element, formValue, '999-99-9999');
    if (!isError) {
      removeError(element.element_id);
    } else {
      setError({ id: element.element_id, error: true, error_message: message });
    }
  };
  return (
    <>
      {element.label !== '' && (
        <Grid xs={12} md={6} spacing={1} className='grid-container plt-8'>
          <Grid xs={12} md={12} item={true}>
            <label
              dangerouslySetInnerHTML={{
                __html: languageForLabel
                  ? translations?.[languageForLabel]?.elements?.[element.element_id]
                    ? translations?.[languageForLabel]?.elements?.[element.element_id]
                    : element?.label
                  : element?.label,
              }}
            />
            <label className='required'>{element.required && '*'}</label>{' '}
            {element?.help_note ? (
              <Tooltip title={element?.help_note}>
                {' '}
                <Help className='f-14 mt-minus-2 cursor-pointer' />
              </Tooltip>
            ) : (
              ''
            )}
          </Grid>
          <Grid xs={12} md={12} item={true}>
            <InputMask
              mask='999-99-9999'
              value={value}
              maskChar='_'
              onChange={handleChange}
              onBlur={handleError}
              disabled={disabled || disabledField}
            >
              {() => (
                <TextField
                  error={error}
                  className='full-width'
                  variant='outlined'
                  helperText={error_message}
                  disabled={disabled || disabledField}
                />
              )}
            </InputMask>
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default SocialNumber;
