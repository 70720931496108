import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Select,
  MenuItem,
  FormControl,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import Loader from '../common/Loader';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    width: '500px',
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const ParticipantTypeModal = ({ open, setOpen, options, onSave }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async (data) => {
    onSave(data?.participantType);
    reset();
  };

  return (
    <div>
      {loading && <Loader />}
      <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id='customized-dialog-title'
          className='customized-dialog-title'
          style={{ fontSize: '16px' }}
        >
          Select Participant Type
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <Box component='form' onSubmit={handleSubmit(handleSave)}>
            <label className='f-13'>Participant Type</label>
            <label className='required'>{'*'}</label>
            <Controller
              name={`participantType`}
              control={control}
              rules={{ required: 'Participant type is required' }}
              defaultValue=''
              render={({ field, fieldState }) => (
                <FormControl fullWidth>
                  <Select {...field}>
                    {options?.map((item) => {
                      return (
                        <MenuItem value={item?.participant_type_id}>
                          {item?.participant_type_name}
                        </MenuItem>
                      );
                    })}
                    {options?.length === 0 && (
                      <MenuItem value=''>Participant type not available.</MenuItem>
                    )}
                  </Select>
                  <Typography className='order-error' color='error'>
                    {fieldState.error ? fieldState.error.message : ''}
                  </Typography>
                </FormControl>
              )}
            />
            {options?.length === 0 && (
              <div className='f-12 error mt-8 fw-500'>
                Please select an order type first to proceed with adding participants or the
                selected order type may not be associated with any participants, or you may have
                already added the required participants.
              </div>
            )}

            <DialogActions className='mt-8'>
              <Button
                autoFocus
                onClick={handleClose}
                className='p-2'
                color='secondary'
                style={{ color: 'grey' }}
              >
                Close
              </Button>
              <Button
                autoFocus
                type='submit'
                variant='contained'
                color='primary'
                className='p-2 btn-participentType'
              >
                Save
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};
export default ParticipantTypeModal;
