import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Dialog, DialogActions, FormHelperText, Grid, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ClearIcon from '@mui/icons-material/Clear';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { styled } from '@mui/material/styles';
import { css } from '@mui/system';
import { errorChecker } from '../../../../utils/helpers';
import useRedux from '../../../../utils/hooks/useRedux';
import {
  uploadDocument,
  deleteDocument,
  getDocumentDetails,
} from '../../../../services/questions/home';
import { setDocuments, clearFormValue } from '../../../../store/slices/questionSlice';
import Dropzone from 'react-dropzone';
import Loader from '../../Loader';
import { Help } from '@mui/icons-material';

const docMap = {
  'text/csv': ['.csv'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls', '.xlsx'],
  'image/png': ['.png', '.jpeg', '.jpg'],
  'application/pdf': ['.pdf'],
};

const DialogTitle = styled('div')(css`
  text-align: center;
  .error-icon {
    color: red;
    margin-top: 32px;
  }
  .error-title {
    font-size: 18px;
    font-weight: bold;
  }
`);

const DialogContent = styled('div')(css`
  font-size: 13px;
  margin: 12px 24px 20px;
  text-align: center;
`);

const Upload = ({ element, orderId, disabled }) => {
  const { code, participantNo } = useParams();
  const selectedLanguage = useSelector((state) => state.questionSlice.languages)?.[code];
  const languageForLabel = selectedLanguage && selectedLanguage !== 'en' ? selectedLanguage : '';
  const translations = useSelector((state) => state.questionSlice.convertedTexts)?.find(
    (que) => que.tx_id === code
  );
  const dispatch = useDispatch();
  const authData = useSelector((state) => state?.authSlice);
  const order_part_participant_id = useSelector(
    (state) => state.questionSlice.questionDetails
  )?.find((que) => que.tx_id === code)?.order_part_participant_id;
  const { setFormValue, getErrorDetails, setError, removeError, getDocument, getFormValue } =
    useRedux();
  const formValue = getFormValue(element.element_id);
  const docData = getDocument(element.element_name);
  const maxFiles = element?.max_allowed || 3;
  let { error, error_message } = getErrorDetails(element.element_id) || {
    error: false,
    error_message: '',
  };
  const [uploading, setUploading] = useState(false);
  const [uploadingError, setUploadingError] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState(docMap);

  useEffect(() => {
    if (element?.document_types?.length) {
      let tempAcceptedFiles = {};
      element?.document_types?.forEach((type) => {
        tempAcceptedFiles[type] = docMap[type];
      });
      setAcceptedFiles(tempAcceptedFiles);
    }
  }, [element.element_id]);

  useEffect(() => {
    if (docData?.[0]?.file_name) {
      setFormValue({ id: element.element_id, value: docData?.[0]?.file_name });
    } else {
      if (formValue) dispatch(clearFormValue({ ids: [element?.element_id], tx_id: code }));
    }
  }, [docData]);

  const getDocuments = async (orderId) => {
    const documentData = await getDocumentDetails(
      {
        order_id: orderId,
      },
      authData?.[code]?.token
    );
    if (documentData?.success) {
      dispatch(setDocuments({ documents: documentData?.response?.files, tx_id: code }));
    }
  };

  const uploadFile = async (files) => {
    setUploading(true);
    if (files.length > maxFiles - docData?.length) {
      toast.error(`You can upload maximum ${maxFiles - docData?.length} files`);
      const errorData = errorChecker(element, formValue);
      if (errorData.isError) {
        setError({
          id: element.element_id,
          error: true,
          error_message: `You can upload maximum ${maxFiles - docData?.length} files`,
        });
      }
      return setUploading(false);
    }
    const form = new FormData();
    for (let x = 0; x < files?.length; x++) {
      form.append('document_file[]', files[x]);
    }
    form.append('order_id', orderId);
    form.append('order_part_participant_id', order_part_participant_id);
    form.append('description', element?.label || 'Order document');
    form.append('form_element_name', element.element_name);
    form.append('participant_no', participantNo || 1);
    const uploadData = await uploadDocument(
      form,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
      authData?.[code]?.token
    );
    if (uploadData?.success) {
      await getDocuments(orderId);
      removeError(element.element_id);
    } else {
      toast.error(uploadData?.message || 'Something went wrong!');
      setError({
        id: element.element_id,
        error: true,
        error_message: `Please try again`,
      });
    }
    setUploading(false);
  };

  const deleteFile = async (id) => {
    setUploading(true);
    const deleted = await deleteDocument(
      id,
      {
        form_element_name: element.element_name,
      },
      authData?.[code]?.token
    );
    if (deleted?.success) {
      await getDocuments(orderId);
      toast.success(deleted?.message);
    } else {
      toast.error(deleted?.message || 'Something went wrong!');
    }
    setUploading(false);
  };

  const handleClose = () => {
    setUploadingError(false);
  };

  return (
    <>
      {uploading && <Loader />}
      {element.label !== '' && (
        <Grid xs={12} md={12} spacing={1} className='grid-container plt-8'>
          <Grid xs={12} item={true}>
            <label
              dangerouslySetInnerHTML={{
                __html: languageForLabel
                  ? translations?.[languageForLabel]?.elements?.[element.element_id]
                    ? translations?.[languageForLabel]?.elements?.[element.element_id]
                    : element?.label
                  : element?.label,
              }}
            />
            <label className='required'>{element.required && '*'}</label>{' '}
            {element?.help_note ? (
              <Tooltip title={element?.help_note}>
                {' '}
                <Help className='f-14 cursor-pointer mt-minus-2' />
              </Tooltip>
            ) : (
              ''
            )}
          </Grid>
          <Grid xs={12} item={true} className='mt-8'>
            <Dropzone
              onDrop={(acceptedFiles) => uploadFile(acceptedFiles)}
              disabled={maxFiles - docData?.length <= 0 || disabled}
              accept={acceptedFiles}
            >
              {({ getRootProps, getInputProps, isDragActive, isFileDialogActive }) => (
                <div
                  className={
                    isDragActive || isFileDialogActive ? 'drag-active dropzone' : 'dropzone'
                  }
                  style={{ cursor: maxFiles - docData?.length <= 0 ? 'not-allowed' : 'pointer' }}
                >
                  <div className='dz-message d-flex flex-column justify-center' {...getRootProps()}>
                    <input {...getInputProps()} name='file' />
                    <i className='h2 d-inline-block'>
                      {maxFiles - docData?.length <= 0 ? (
                        <CloudOffIcon className='ms-2' fontSize={'medium'} />
                      ) : (
                        <CloudUploadIcon className='ms-2' fontSize={'medium'} />
                      )}
                    </i>
                    <p className='mb-0 mt-0' style={{ fontWeight: '600', fontSize: '13px' }}>
                      Drag & drop you files here or select files.
                    </p>
                    <p className='mb-0 mt-0' style={{ color: 'grey' }}>
                      <small>
                        {maxFiles - docData?.length <= 0
                          ? `(You have already uploaded maximum allowed files.)`
                          : `(You can upload maximum ${maxFiles - docData?.length} files.)`}
                      </small>
                    </p>
                  </div>
                </div>
              )}
            </Dropzone>
          </Grid>
          <FormHelperText error={true}>{error_message || ''}</FormHelperText>
          <Grid xs={12} item={true}>
            {(docData || []).map((doc, index) => {
              return (
                <div key={doc?.id} className={`file-preview ${index === 0 ? '' : 'mt-8'}`}>
                  <div className='file-icon'>
                    <InsertDriveFileIcon />
                  </div>
                  <div
                    className='file-details'
                    onClick={() => {
                      if (participantNo && participantNo != 1) return;
                      if (doc?.url) window.open(doc?.url, '_blank');
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <div>{doc?.file_name}</div>
                  </div>
                  <div className='file-close'>
                    <Button
                      variant='link'
                      onClick={() => {
                        if (participantNo && participantNo != 1) return;
                        deleteFile(doc?.id);
                      }}
                      disabled={disabled}
                    >
                      <ClearIcon fontSize='small' />
                    </Button>
                  </div>
                </div>
              );
            })}
          </Grid>
        </Grid>
      )}
      <Dialog open={uploadingError} onClose={() => handleClose()} className='alert-dialog'>
        <DialogTitle>
          <div className='error-icon'>
            <ErrorOutlineIcon fontSize='large' />
          </div>
          <div className='error-title'>There was an error</div>
        </DialogTitle>
        <DialogContent>
          There was an error while processing your file. <br />
          Make sure you are connected to the internet and <br />
          have a stable connection during this process
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Upload;
