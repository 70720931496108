import { authorizedApi } from '../../utils/api';

export const getConfigurations = (id, token) => {
  let url = `/admin/settings`;
  if (id) url += `?client_id=${id}`;
  return authorizedApi(token).get(url);
};

export const setConfigurations = (data, token) => {
  return authorizedApi(token).post(`/admin/settings`, data);
};

export const saveConfig = (data, token) => {
  return authorizedApi(token).post(`/admin/save/listing/config`, data);
};

export const getDocuments = (token, client_id, office_id, currPage = 1, per_page = 10) => {
  let url = `/admin/additional/docs/config?page=${currPage}&per_page=${per_page}`;
  if (client_id) url += `&client_id=${client_id}`;
  if (office_id) url += `&client_office_id=${office_id}`;
  return authorizedApi(token).get(url);
};

export const getOrderTypes = (token, client_id, office_id, currPage = 1, per_page = 10) => {
  let url = `admin/order/types?page=${currPage}&per_page=${per_page}`;
  if (client_id) url += `&client_id=${client_id}`;
  if (office_id) url += `&client_office_id=${office_id}`;
  return authorizedApi(token).get(url);
};

export const saveDocuments = (token, data) => {
  return authorizedApi(token).post(`/admin/save/additional/docs/config`, data);
};

export const addOrderType = (token, data) => {
  return authorizedApi(token).post(`/admin/order/types`, data);
};

export const editOrderType = (token, data, id) => {
  return authorizedApi(token).put(`admin/order/types/${id}`, data);
};