import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Drawer, TextField, Grid, Typography, Divider, Button, Box, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import Select from 'react-select';
import { DocElementType } from '../../../utils/constants';
import { Clear, Close, CloudUpload, Help, InsertDriveFile } from '@mui/icons-material';
import Dropzone from 'react-dropzone';
import { deleteDocument, getDocument, uploadDocument } from '../../../services/admin/templates';
import { useSelector } from 'react-redux';
import Loader from '../../common/Loader';
import { toast } from 'react-toastify';

const fileTypeOptions = [
  { label: 'CSV', value: 'text/csv' },
  { label: 'Excel', value: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
  { label: 'Image', value: 'image/png' },
  { label: 'PDF', value: 'application/pdf' },
];
const FieldConfigurationDrawer = (props) => {
  const { id } = useParams();
  const { admin } = useSelector((state) => state?.authSlice);
  const currentElement = props?.allElements?.[props?.currentIndex];
  const [sample, setSample] = useState('');
  const [regex, setRegex] = useState('');
  const [note, setNote] = useState('');
  const [docTypes, setDocTypes] = useState([]);
  const [docData, setDocData] = useState([]);
  const [maxSize, setMaxSize] = useState();
  const [maxFileAllowed, setMaxFileAllowed] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSample(currentElement?.sample_input || '');
    setRegex(currentElement?.match_pattern || '');
    setNote(currentElement?.help_note || '');
    setMaxSize(currentElement?.max_size || '');
    setMaxFileAllowed(currentElement?.max_allowed || '');

    if (currentElement?.document_types?.length) {
      let tempDocTypes = [];
      currentElement?.document_types?.forEach((type) => {
        const foundOption = fileTypeOptions.find((option) => option.value === type);
        tempDocTypes.push(foundOption);
      });
      setDocTypes(tempDocTypes);
    } else {
      setDocTypes([]);
    }

    if (currentElement?.element_type === DocElementType.MediaLink) {
      getDocumentData();
    }
  }, [props?.currentIndex, currentElement]);

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  }));

  const handleHelpNoteChange = (e) => {
    setNote(e.target.value);
    props.setAllElements((elements) => {
      let tempElements = elements;
      tempElements[props.currentIndex].help_note = e.target.value;
      return tempElements;
    });
  };

  const handleSampleChange = (e) => {
    setSample(e.target.value);
    props.setAllElements((elements) => {
      let tempElements = elements;
      tempElements[props.currentIndex].sample_input = e.target.value;
      return tempElements;
    });
  };

  const handleRegexChange = (e) => {
    setRegex(e.target.value);
    props.setAllElements((elements) => {
      let tempElements = elements;
      tempElements[props.currentIndex].match_pattern = e.target.value;
      return tempElements;
    });
  };

  const handleSizeChange = (e) => {
    setMaxSize(e.target.value);
    props.setAllElements((elements) => {
      let tempElements = elements;
      tempElements[props.currentIndex].max_size = e.target.value;
      return tempElements;
    });
  };

  const handleMaxAllowedChange = (e) => {
    setMaxFileAllowed(e.target.value);
    props.setAllElements((elements) => {
      let tempElements = elements;
      tempElements[props.currentIndex].max_allowed = e.target.value;
      return tempElements;
    });
  };

  const handleDocTypes = (values) => {
    setDocTypes(values);
    props.setAllElements((elements) => {
      let tempElements = elements;
      tempElements[props.currentIndex].document_types = values.map((value) => value.value);
      return tempElements;
    });
  };

  const getDocumentData = async () => {
    setLoading(true);
    const res = await getDocument(id, currentElement?.element_name, admin?.token);
    if (res.success) {
      setDocData(res?.response?.files);
    }
    setLoading(false);
  };

  const uploadFile = async (files) => {
    setLoading(true);

    const form = new FormData();
    // for (let x = 0; x < files?.length; x++) {
    //   form.append('document_file[]', files[x]);
    // }

    form.append('doc_file', files[0]);
    form.append('element_name', currentElement.element_name);
    form.append('template_id', id);
    const uploadData = await uploadDocument(
      form,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
      admin?.token
    );
    if (uploadData?.success) {
      await getDocumentData();
    } else {
      toast.error(uploadData?.message || 'Something went wrong!');
    }
    setLoading(false);
  };

  const deleteFile = async () => {
    setLoading(true);
    const deleted = await deleteDocument(id, currentElement?.element_name, admin?.token);
    if (deleted?.success) {
      await getDocumentData();
    } else {
      toast.error(deleted?.message || 'Something went wrong!');
    }
    setLoading(false);
  };

  return (
    <Drawer
      sx={{
        width: 500,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 500,
          boxSizing: 'border-box',
        },
      }}
      anchor='right'
      open={props.visible}
      onClose={() => {
        props.setVisible(false);
      }}
    >
      <DrawerHeader>
        <Typography variant='h6'>Extra Configuration</Typography>
        <Close
          sx={{ position: 'absolute', right: '10px', top: '20px', color: 'grey' }}
          fontSize='small'
          onClick={() => {
            props.setVisible(false);
          }}
          className='cursor-pointer'
        />
      </DrawerHeader>
      <Divider />
      {loading && <Loader />}
      <Box className='p-12 drawer-form'>
        <div>
          <label htmlFor='participantType' style={{ fontSize: '13px' }}>
            Note{' '}
            <Tooltip title='This note will be visible to participants when they view a particular question. It will guide them about the question.'>
              <Help className='f-14 cursor-pointer' />
            </Tooltip>
          </label>
          <textarea
            fullWidth
            rows={4}
            value={note}
            style={{ width: '100%', borderRadius: '10px', borderColor: '#cac9c9' }}
            onChange={handleHelpNoteChange}
          />
        </div>
        {[DocElementType.TextField, DocElementType.SocialNumber].includes(
          currentElement?.element_type
        ) && (
          <div>
            <label htmlFor='participantType' style={{ fontSize: '13px' }}>
              Regular Expression
            </label>
            <TextField
              fullWidth
              name='regex'
              value={regex}
              style={{ marginTop: '4px', marginBottom: '8px' }}
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              onChange={handleRegexChange}
            />

            <label htmlFor='participantType' style={{ fontSize: '13px' }}>
              Sample Input{' '}
            </label>
            <TextField
              fullWidth
              name='sample'
              value={sample}
              style={{ marginTop: '4px' }}
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              onChange={handleSampleChange}
            />
            <div style={{ fontSize: '12px', color: 'grey', marginTop: '4px' }}>
              For Phone Number : (999) 999-9999 (This can be used as mask placeholder for user)
            </div>
          </div>
        )}

        {[DocElementType.Upload, DocElementType.File].includes(currentElement?.element_type) && (
          <div>
            <label htmlFor='participantType' style={{ fontSize: '13px', marginTop: '8px' }}>
              Maximum Files Allowed
            </label>
            <TextField
              fullWidth
              name='maxFiles'
              value={maxFileAllowed}
              style={{ marginTop: '4px', marginBottom: '8px' }}
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              onChange={handleMaxAllowedChange}
            />
            <label htmlFor='participantType' style={{ fontSize: '13px', marginTop: '8px' }}>
              Maximum Size (in MB)
            </label>
            <TextField
              fullWidth
              name='size'
              value={maxSize}
              style={{ marginTop: '4px', marginBottom: '8px' }}
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              onChange={handleSizeChange}
            />
            <label htmlFor='participantType' style={{ fontSize: '13px' }}>
              Document Types
            </label>
            <Select
              isMulti
              value={docTypes}
              onChange={handleDocTypes}
              name='colors'
              options={fileTypeOptions}
              className='basic-multi-select'
              classNamePrefix='select'
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: '40px',
                  backgroundColor: '#f8f8f8',
                  cursor: 'pointer',
                }),
              }}
            />
          </div>
        )}

        {[DocElementType.MediaLink].includes(currentElement?.element_type) && (
          <Grid container spacing={1} className='grid-container'>
            <Grid xs={12} item={true}>
              <label htmlFor='participantType' style={{ fontSize: '13px' }}>
                {'Upload ' + currentElement?.label}
              </label>
            </Grid>
            <Grid xs={12} item={true}>
              <Dropzone
                onDrop={(acceptedFiles) => uploadFile(acceptedFiles)}
                disabled={docData?.length ? true : false}
              >
                {({ getRootProps, getInputProps, isDragActive, isFileDialogActive }) => (
                  <div
                    className={
                      isDragActive || isFileDialogActive ? 'drag-active dropzone' : 'dropzone'
                    }
                    style={{ cursor: `${docData?.length ? 'not-allowed' : 'pointer'}` }}
                  >
                    <div
                      className='dz-message d-flex flex-column justify-center'
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} name='file' />
                      <i className='h2 d-inline-block'>
                        <CloudUpload className='ms-2' fontSize={'medium'} />
                      </i>
                      <p className='mb-0 mt-0' style={{ fontWeight: '600', fontSize: '12px' }}>
                        Drag & drop you files here or select files.
                      </p>
                    </div>
                  </div>
                )}
              </Dropzone>
            </Grid>
            <Grid xs={12} item={true}>
              {docData?.length ? (
                <div key={1} className={`file-preview`}>
                  <div className='file-icon'>
                    <InsertDriveFile />
                  </div>
                  <div
                    className='file-details'
                    onClick={() => {
                      if (docData?.[0]?.url) window.open(docData?.[0]?.url, '_blank');
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <div>{currentElement?.label}</div>
                  </div>
                  <div className='file-close'>
                    <Button
                      variant='link'
                      onClick={() => {
                        deleteFile();
                      }}
                    >
                      <Clear fontSize='small' />
                    </Button>
                  </div>
                </div>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        )}
      </Box>
    </Drawer>
  );
};

export default FieldConfigurationDrawer;
