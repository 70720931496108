import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { MenuItem, Select, Switch, TextField, IconButton, Button, Tooltip } from '@mui/material';
import { DocElementType } from '../../../utils/constants';
import ManageOptionsDrawer from './ManageOptionsDrawer';
import TuneIcon from '@mui/icons-material/Tune';
import _, { cloneDeep } from 'lodash';
import { arrayMoveImmutable } from 'array-move';
import { randomUUID } from '../../../utils/helpers';
import FormPreview from '../../../pages/admin/templates/FormPreview';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteModal from '../../common/DeleteModal';
import { toast } from 'react-toastify';
import SettingsIcon from '@mui/icons-material/Settings';
import FieldConfigurationDrawer from './FieldConfigurationDrawer';
import StaticPaginationTable from '../../common/StaticPaginationTable';
import AddElementModal from './AddElementModal';
import { Help } from '@mui/icons-material';

const CreateQuestionsForm = forwardRef((props, ref) => {
  const rowsPerPageOptions = [10, 20, 30, 50, 100];
  const prefix = props?.templateDetails?.participant_type_name?.replace(' ', '_')?.toLowerCase();
  const [allElements, setAllElements] = useState([]);
  const [formElements, setFormElements] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isOptionDrawerVisible, setOptionsDrawerVisible] = useState(false);
  const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);
  const [isConfigDrawerOpen, setConfigDrawerOpen] = useState(false);
  const [isElementModalOpen, setIsElementModalOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [page, setPage] = useState(0);
  const [id, setId] = useState('');

  useImperativeHandle(ref, () => ({
    convertToElementData,
    validateCurrentElements,
  }));

  useEffect(() => {
    setPage(0);
  }, [props.currentSection, props.currentGroup]);

  const columns = [
    { id: 'elementName', label: 'Element Name', type: 'jsx' },
    { id: 'labelField', label: 'Label', type: 'jsx' },
    { id: 'elementType', label: 'Element Type', type: 'jsx' },
    { id: 'parentElement', label: 'Parent Element', type: 'jsx' },
    { id: 'parentValue', label: 'Parent Value', type: 'jsx' },
    { id: 'requiredField', label: 'Required', type: 'jsx' },
    {
      id: 'displayField',
      label: 'Display',
      type: 'jsx',
      render: () => (
        <div style={{ width: '70px' }} className='d-flex flex-col align-items-center'>
          Display{' '}
          <Tooltip title='If the toggle is on, the field will be displayed on the participant form.'>
            <Help className='f-14 cursor-pointer ml-4' />
          </Tooltip>
        </div>
      ),
    },
    {
      id: 'privateField',
      label: 'Private',
      type: 'jsx',
      render: () => (
        <div style={{ width: '70px' }} className='d-flex flex-col align-items-center'>
          Private{' '}
          <Tooltip
            title={`If the toggle is on, additional participants can't see each other's fields in the case of sequential flow.`}
          >
            <Help className='f-14 cursor-pointer ml-4' />
          </Tooltip>
        </div>
      ),
    },
    {
      id: 'autoPopulateField',
      label: 'Auto Populate',
      type: 'jsx',
      render: () => (
        <div style={{ width: '120px' }} className='d-flex flex-col align-items-center'>
          Auto Populate{' '}
          <Tooltip
            title={`If the toggle is on, the field will be populated with public records if found`}
          >
            <Help className='f-14 cursor-pointer ml-4' />
          </Tooltip>
        </div>
      ),
    },
    { id: 'optionsList', label: 'Options', type: 'jsx' },
    { id: 'actions', label: 'Actions', type: 'jsx' },
  ];

  useEffect(() => {
    if (props?.data?.length) {
      fetchAllElements();
    } else {
      setAllElements([]);
      setFormElements([]);
    }
  }, [props?.data]);

  useEffect(() => {
    createFormElements();
  }, [allElements]);

  const fetchAllElements = async () => {
    let elementsData = cloneDeep(props.data);
    let tempElements = [];
    const answer = async (element, parent_element = '', parent_value = '') => {
      tempElements.push({ ...element, parent_element, parent_value });
      if (element.options.length) {
        for (const option of element.options) {
          for (const subElement of option.elements) {
            await answer(subElement, element.element_id, option.option_id);
          }
        }
      }
    };
    for (const element of elementsData) {
      await answer(element);
    }
    setAllElements(tempElements);
  };

  const convertToElementData = () => {
    const elementMap = new Map();

    // Create a map of elements using their IDs for efficient lookup
    for (const element of allElements) {
      if (element?.options?.length) element?.options?.forEach((option) => (option.elements = []));
      elementMap.set(element.element_id, element);
    }

    const elementData = [];

    for (const element of allElements) {
      const parentElementId = element.parent_element;

      if (!parentElementId) {
        delete element.parent_element;
        delete element.parent_value;
        elementData.push(element);
      } else {
        const parentElement = elementMap.get(parentElementId);
        if (parentElement) {
          let foundedOption = parentElement.options.find(
            (option) => option.option_id === element.parent_value
          );
          delete element.parent_element;
          delete element.parent_value;

          if (foundedOption?.elements?.length) {
            foundedOption?.elements?.push(element);
          } else {
            foundedOption.elements = [element];
          }
        }
      }
    }
    props.setData((data) => {
      let tempData = data;
      if (
        tempData?.sections?.[props.currentSection]?.groups?.length &&
        tempData.sections[props.currentSection].groups[props.currentGroup]
      )
        tempData.sections[props.currentSection].groups[props.currentGroup].elements = elementData;
      return tempData;
    });
  };

  const handleChange = (field, value, index) => {
    setAllElements((prevFormElements) => {
      const tempElements = [...prevFormElements];
      tempElements[index][field] = value;
      return tempElements;
    });
  };

  const validateCurrentElements = () => {
    let isValidated = true;
    for (const element of allElements) {
      if (!element?.label?.length && element.element_type !== DocElementType.Media) {
        isValidated = false;
        break;
      }
    }
    return isValidated;
  };

  const handleElementTypeChange = (value, index) => {
    setAllElements((prevFormElements) => {
      let tempElements = [...prevFormElements];

      if (
        [DocElementType.RadioButton, DocElementType.SelectBox, DocElementType.SSelectBox].includes(
          tempElements[index].element_type
        ) &&
        ![DocElementType.RadioButton, DocElementType.SelectBox, DocElementType.SSelectBox].includes(
          value
        )
      ) {
        tempElements[index].options = [];
        tempElements.forEach((element, idx) => {
          if (element?.parent_element === tempElements[index].element_id) {
            tempElements[idx].parent_element = null;
            tempElements[idx].parent_value = null;
          }
        });
        tempElements[index]['element_type'] = value;
        // tempElements = sortElements(tempElements);
      } else if (
        [DocElementType.RadioButton, DocElementType.SelectBox, DocElementType.SSelectBox].includes(
          value
        )
      ) {
        tempElements[index]['element_type'] = value;
        if (!tempElements[index]['options']?.length) {
          tempElements[index]['options'] = [
            {
              elements: [],
              option_id: randomUUID(),
              option_name: 'Default Option',
            },
          ];
        }
      } else {
        tempElements[index]['element_type'] = value;
      }
      if ([DocElementType.Media, DocElementType.MediaLink].includes(value)) {
        tempElements[index]['options'] = [
          {
            elements: [],
            option_id: randomUUID(),
            option_name: 'www.test.com',
          },
        ];
      }
      return tempElements;
    });
  };

  const handleParentTypeChange = (value, index) => {
    setAllElements((prevFormElements) => {
      let tempElements = [...prevFormElements];
      const parentElement = prevFormElements.find((elem) => elem.element_id === value);
      if (parentElement) {
        tempElements[index]['parent_element'] = value;
        tempElements[index]['parent_value'] = parentElement.options[0].option_id || '';
        // tempElements = sortElements(tempElements);
      }
      return tempElements;
    });
  };

  const createFormElements = async () => {
    let tempFormElements = allElements.map((element, index) => {
      return {
        ...element,
        elementName: (props) => <div style={{ width: '200px' }}>{element.element_name}</div>,
        labelField: (props) => (
          <>
            <TextField
              id='outlined-basic'
              key={props.element_id}
              defaultValue={props?.label ? props.label : ''}
              // value={props?.label ? props.label : ''}
              onChange={(e) => {
                handleChange('label', e.target.value, index);
              }}
              className='full-width'
              required={true}
              variant='outlined'
              inputProps={{
                role: 'presentation',
                autoComplete: 'off',
              }}
              disabled={props?.b_field}
              style={{ width: '250px', backgroundColor: 'white', background: 'white' }}
            />
            {!props?.label?.length && element.element_type !== DocElementType.Media && (
              <span className='error mb-8 mt-4' style={{ display: 'inherit', fontSize: '12px' }}>
                Please enter label for the field.
              </span>
            )}
          </>
        ),
        elementType: (props) => (
          <Select
            key={props.element_id}
            className='full-width'
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={props.element_type || ''}
            onChange={(e) => {
              handleElementTypeChange(e.target.value, index);
            }}
            style={{ width: '180px' }}
          >
            {Object.keys(DocElementType).map((option, idx) => {
              return (
                <MenuItem value={DocElementType[option]} key={idx}>
                  {option}
                </MenuItem>
              );
            })}
          </Select>
        ),
        parentElement: (props) => {
          let nestedChildren = [];
          let parentElm = '';
          let options = allElements?.filter((option) => {
            if (option.parent_element == props.element_id) {
              parentElm = option.element_id;
              nestedChildren.push(option.element_id);
            }
            return (
              [
                DocElementType.RadioButton,
                DocElementType.SelectBox,
                DocElementType.SSelectBox,
              ].includes(option.element_type) && option.element_name !== props.element_name
            );
          });
          function findChildren(array, parent_element) {
            let children = [];

            // Iterate through the array to find children of the given parent ID
            array.forEach((obj) => {
              if (obj.parent_element === parent_element) {
                children.push(obj.element_id);
                // Recursively find children of this child
                children = children.concat(findChildren(array, obj.element_id));
              }
            });

            return children;
          }
          if (parentElm?.length) {
            let deeplyNestedElems = findChildren(options, parentElm);
            nestedChildren = [...nestedChildren, ...deeplyNestedElems];
          }
          options = options.filter((option) => !nestedChildren.includes(option.element_id));
          return (
            <Select
              key={props.element_id}
              className='full-width'
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={props.parent_element || ''}
              style={{ width: '250px' }}
              disabled={!options?.length}
              onChange={(e) => {
                handleParentTypeChange(e.target.value, index);
              }}
            >
              {options.map((option, idx) => {
                return (
                  <MenuItem value={option.element_id} key={idx}>
                    {option.element_name}
                  </MenuItem>
                );
              })}
            </Select>
          );
        },
        parentValue: (props) => {
          const parentElement = allElements.find(
            (option) => option.element_id == props.parent_element
          );

          return (
            <Select
              key={props.element_id}
              className='full-width'
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={props.parent_value || ''}
              disabled={!parentElement?.options?.length}
              style={{ width: '250px' }}
              onChange={(e) => {
                handleChange('parent_value', e.target.value, index);
              }}
            >
              {parentElement?.options?.map((option, idx) => {
                return (
                  <MenuItem value={option.option_id} key={idx}>
                    {option.option_name}
                  </MenuItem>
                );
              })}
            </Select>
          );
        },
        requiredField: (props) => (
          <Switch
            key={props.element_id}
            checked={props.required}
            size='small'
            onChange={(e) => {
              handleChange('required', e.target.checked, index);
            }}
          />
        ),
        displayField: (props) => (
          <Switch
            key={props.element_id}
            checked={props.display}
            size='small'
            onChange={(e) => {
              handleChange('display', e.target.checked, index);
            }}
          />
        ),
        privateField: (props) => (
          <Switch
            key={props.element_id}
            checked={props.is_private}
            size='small'
            onChange={(e) => {
              handleChange('is_private', e.target.checked, index);
            }}
          />
        ),
        autoPopulateField: (props) => (
          <Switch
            key={props.element_id}
            checked={props.auto_populate_value !== undefined ? props.auto_populate_value : true}
            size='small'
            onChange={(e) => {
              handleChange('auto_populate_value', e.target.checked, index);
            }}
          />
        ),
        optionsList: (props) => (
          <IconButton
            className='icon-btn'
            disabled={
              ![
                DocElementType.RadioButton,
                DocElementType.SelectBox,
                DocElementType.SSelectBox,
                DocElementType.Media,
              ].includes(props.element_type)
            }
            onClick={() => {
              setCurrentIndex(index);
              setOptionsDrawerVisible(true);
            }}
          >
            <TuneIcon fontSize='small' />
          </IconButton>
        ),
        actions: (props) => (
          <div className='d-flex flex-row'>
            <IconButton
              onClick={() => {
                setId(props.element_id);
                setShowDeleteModal(true);
              }}
            >
              <DeleteIcon fontSize='small' color='error' />
            </IconButton>
            <IconButton
              onClick={() => {
                setCurrentIndex(index);
                setConfigDrawerOpen(true);
              }}
              // disabled={
              //   ![
              //     DocElementType.TextField,
              //     DocElementType.SocialNumber,
              //     DocElementType.Upload,
              //     DocElementType.File,
              //     DocElementType.MediaLink,
              //   ].includes(props.element_type)
              // }
            >
              <SettingsIcon fontSize='small' />
            </IconButton>
          </div>
        ),
      };
    });
    setFormElements(tempFormElements);
  };

  const handleAddElement = (elemType = 'regular') => {
    if (elemType === 'bank') {
      setAllElements((prevFormElements) => {
        const newElems = [
          {
            label: 'Bank Account Number:',
            b_field: 1,
            display: true,
            help_id: '0',
            options: [],
            required: true,
            change_log: [],
            element_id: randomUUID(),
            user_value: null,
            element_name: prefix + '_field_' + Math.floor(10000 + Math.random() * 90000),
            element_type: 'T',
            verification: {
              type: 'static',
              verification_source: [],
            },
            match_pattern: null,
            confirmed_value: [],
            is_private: true,
            auto_populate_value: true,
          },
          {
            label: 'Bank ABA (Routing Number):',
            display: true,
            b_field: 1,
            help_id: '0',
            options: [],
            required: true,
            change_log: [],
            element_id: randomUUID(),
            user_value: null,
            element_name: prefix + '_field_' + Math.floor(10000 + Math.random() * 90000),
            element_type: 'T',
            verification: {
              type: 'static',
              verification_source: [],
            },
            match_pattern: null,
            confirmed_value: [],
            is_private: true,
            auto_populate_value: true,
          },
          {
            label: 'Name on Account:',
            display: true,
            help_id: '0',
            b_field: 1,
            options: [],
            required: true,
            change_log: [],
            element_id: randomUUID(),
            user_value: null,
            element_name: prefix + '_field_' + Math.floor(10000 + Math.random() * 90000),
            element_type: 'T',
            verification: {
              type: 'static',
              verification_source: [],
            },
            match_pattern: null,
            confirmed_value: [],
            is_private: true,
            auto_populate_value: true,
          },
          {
            label: 'Bank Name',
            display: true,
            help_id: '0',
            b_field: 1,
            options: [],
            required: true,
            change_log: [],
            element_id: randomUUID(),
            user_value: null,
            element_name: prefix + '_field_' + Math.floor(10000 + Math.random() * 90000),
            element_type: 'T',
            verification: {
              type: 'static',
              verification_source: [],
            },
            match_pattern: null,
            confirmed_value: [],
            is_private: true,
            auto_populate_value: true,
          },
          {
            label: 'Bank City:',
            display: true,
            help_id: '0',
            b_field: 1,
            options: [],
            required: true,
            change_log: [],
            element_id: randomUUID(),
            user_value: null,
            element_name: prefix + '_field_' + Math.floor(10000 + Math.random() * 90000),
            element_type: 'T',
            verification: {
              type: 'static',
              verification_source: [],
            },
            match_pattern: null,
            confirmed_value: [],
            is_private: true,
            auto_populate_value: true,
          },
          {
            label: 'Bank State:',
            display: true,
            help_id: '0',
            b_field: 1,
            options: [
              {
                elements: [],
                option_id: '33183037-2e8e-4dcc-80ac-1ade35896247',
                option_name: 'AL',
              },
              {
                elements: [],
                option_id: '68b946f7-0174-453b-94c7-2e8a6317d746',
                option_name: 'AK',
              },
              {
                elements: [],
                option_id: '2d2c40af-cc33-40b5-a4ec-0e8bf5aca5fa',
                option_name: 'AZ',
              },
              {
                elements: [],
                option_id: '3b03e700-5ff9-4278-b6c2-dbf52253d525',
                option_name: 'AR',
              },
              {
                elements: [],
                option_id: 'fd7dea8a-02df-4448-8b3c-7e36a8207a38',
                option_name: 'CA',
              },
              {
                elements: [],
                option_id: '8a969bf0-0d4a-4259-936b-c2bcc4c99fcd',
                option_name: 'CO',
              },
              {
                elements: [],
                option_id: '347407bb-4b14-4218-85e7-22e7189c9410',
                option_name: 'CT',
              },
              {
                elements: [],
                option_id: '69a09a28-6739-42c1-9efd-1a10ceaf9e2b',
                option_name: 'DC',
              },
              {
                elements: [],
                option_id: '5784c6e4-165b-4352-8130-fa10f607d8b5',
                option_name: 'DE',
              },
              {
                elements: [],
                option_id: '0e297e03-b814-4380-82cc-b48ebb311f65',
                option_name: 'FL',
              },
              {
                elements: [],
                option_id: '128fd61c-dc28-45ad-81f7-ee0236eb53b4',
                option_name: 'GA',
              },
              {
                elements: [],
                option_id: 'b360f32d-be5e-45ca-91eb-ff6025544726',
                option_name: 'HI',
              },
              {
                elements: [],
                option_id: '38418dea-5f5d-47a6-9e37-30921fb67e73',
                option_name: 'IA',
              },
              {
                elements: [],
                option_id: '6fce4a8c-7d93-43b9-abe1-14513f3a9443',
                option_name: 'ID',
              },
              {
                elements: [],
                option_id: 'af277285-8445-48d1-8431-39a56a42ceba',
                option_name: 'IL',
              },
              {
                elements: [],
                option_id: '29bbc084-8ccf-423a-b37e-f1072e2eb86d',
                option_name: 'IN',
              },
              {
                elements: [],
                option_id: 'f276d74e-b58a-46b9-a1f8-82bf9ee5c79a',
                option_name: 'KS',
              },
              {
                elements: [],
                option_id: 'bc1d0ae7-1f48-41da-8404-93f58cc241b2',
                option_name: 'KY',
              },
              {
                elements: [],
                option_id: 'd5777128-5c8f-433d-a310-dd3985b32df1',
                option_name: 'LA',
              },
              {
                elements: [],
                option_id: 'bf2edea7-ce75-4880-9149-4447ad6cae78',
                option_name: 'MA',
              },
              {
                elements: [],
                option_id: '37e9af0d-6852-45e1-b815-2d0833dfa4d2',
                option_name: 'MD',
              },
              {
                elements: [],
                option_id: 'ca3ec9b5-a9be-4f92-baf0-8b0cbbe59a1a',
                option_name: 'ME',
              },
              {
                elements: [],
                option_id: '17282e50-0861-439f-9830-0165943da971',
                option_name: 'MI',
              },
              {
                elements: [],
                option_id: 'c77254c6-e1db-4687-9f1b-4be10af64d3e',
                option_name: 'MN',
              },
              {
                elements: [],
                option_id: '1f7a079c-1e8e-46f4-a074-350035c5ab22',
                option_name: 'MO',
              },
              {
                elements: [],
                option_id: 'ca9751b3-cb34-4a1d-be07-376a932d6670',
                option_name: 'MS',
              },
              {
                elements: [],
                option_id: 'b7e04b00-6568-44cc-b6a2-d22638a6ff52',
                option_name: 'MT',
              },
              {
                elements: [],
                option_id: '6ef0b895-2ab7-464b-ada4-7141a454dbc4',
                option_name: 'NC',
              },
              {
                elements: [],
                option_id: '56d2efb8-7b5a-4f88-bc32-36ff4a418644',
                option_name: 'NE',
              },
              {
                elements: [],
                option_id: '97ef8356-c6e7-4269-8363-5f203e5a1abe',
                option_name: 'NH',
              },
              {
                elements: [],
                option_id: '636c217a-d396-4563-8b15-31fc3108561e',
                option_name: 'NJ',
              },
              {
                elements: [],
                option_id: '69726bf9-a231-4da2-b21f-7b622fb4d712',
                option_name: 'NM',
              },
              {
                elements: [],
                option_id: '59fd8de5-7e06-427d-9036-0c0ecaf36e96',
                option_name: 'NV',
              },
              {
                elements: [],
                option_id: '5154a016-672b-409b-b6f8-ce3d07141d37',
                option_name: 'NY',
              },
              {
                elements: [],
                option_id: 'd975cbd0-a5c3-44d2-8b2b-ff221f5d8720',
                option_name: 'ND',
              },
              {
                elements: [],
                option_id: '77c3381c-3200-47a5-91af-fa25fd2b8ffc',
                option_name: 'OH',
              },
              {
                elements: [],
                option_id: '33099e14-852c-493d-9702-7acb9fb5b771',
                option_name: 'OK',
              },
              {
                elements: [],
                option_id: '8f797c08-68cf-4a57-9b16-e1a493ca39bd',
                option_name: 'OR',
              },
              {
                elements: [],
                option_id: '40638449-5e9e-4073-a2e2-15eaf7ab458d',
                option_name: 'PA',
              },
              {
                elements: [],
                option_id: '65213a11-ea94-4a9f-a140-9c25d3975a85',
                option_name: 'RI',
              },
              {
                elements: [],
                option_id: '2fbeadc9-db22-4226-83ed-1a0995ccd32a',
                option_name: 'SC',
              },
              {
                elements: [],
                option_id: '072ba8f0-b905-4c08-8b85-9e22e5723ba3',
                option_name: 'SD',
              },
              {
                elements: [],
                option_id: '729f9504-439c-4a28-bd15-244fc5ae1a65',
                option_name: 'TN',
              },
              {
                elements: [],
                option_id: '38ea38da-e92b-42b1-864a-f1f3f0f84a06',
                option_name: 'TX',
              },
              {
                elements: [],
                option_id: '885d935b-a707-472f-8f58-5d79a11998ef',
                option_name: 'UT',
              },
              {
                elements: [],
                option_id: '90878fe3-ae16-4ece-961b-1b55f8dc912a',
                option_name: 'VA',
              },
              {
                elements: [],
                option_id: 'b98509f9-852c-4585-b9ee-fe1ed21e54ba',
                option_name: 'VT',
              },
              {
                elements: [],
                option_id: '7b6b99be-fa89-4314-a156-c7dc7da21086',
                option_name: 'WA',
              },
              {
                elements: [],
                option_id: '105f2b5d-6b0b-4e3d-b6c7-b33abaaf7f16',
                option_name: 'WI',
              },
              {
                elements: [],
                option_id: 'da3f8510-abf8-4bbe-9186-d649eb6673ee',
                option_name: 'WV',
              },
              {
                elements: [],
                option_id: '098e9ae0-dc19-4761-8703-6977e2979cad',
                option_name: 'WY',
              },
            ],
            required: true,
            change_log: [],
            element_id: randomUUID(),
            user_value: null,
            element_name: prefix + '_field_' + Math.floor(10000 + Math.random() * 90000),
            element_type: 'S',
            verification: {
              type: 'static',
              verification_source: [],
            },
            match_pattern: null,
            confirmed_value: [],
            is_private: true,
            auto_populate_value: true,
          },
        ];
        return [...prevFormElements, ...newElems];
      });
    } else {
      setAllElements((prevFormElements) => {
        const newElem = {
          label: '',
          display: true,
          help_id: '0',
          options: [],
          required: false,
          change_log: [],
          element_id: randomUUID(),
          user_value: null,
          element_name: prefix + '_field_' + Math.floor(10000 + Math.random() * 90000),
          element_type: 'T',
          verification: {
            type: 'static',
            verification_source: [],
          },
          match_pattern: '',
          sample_input: '',
          confirmed_value: [],
          auto_populate_value: true,
        };
        return [...prevFormElements, newElem];
      });
    }
    setRowsPerPage(100);
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 100);
    setIsElementModalOpen(false);
  };

  const handleDrag = (result) => {
    let tempElements = [...allElements];
    if (tempElements?.[result.source.index]?.parent_element) {
      const parentElementIndex = tempElements.findIndex(
        (element) => element.element_id === tempElements[result.source.index].parent_element
      );
      if (parentElementIndex >= result.destination.index) {
        return toast.warning('You can only drop child element withing parent element.');
      }
    }
    if (tempElements.length > 1) {
      tempElements = arrayMoveImmutable(
        tempElements,
        result.source.index,
        result.destination.index
      );
      setAllElements(tempElements);
    }
  };

  const deleteHandler = async () => {
    setAllElements((prevElements) => {
      let tempElements = [...prevElements];
      tempElements.forEach((element) => {
        if (element.parent_element === id) {
          element.parent_element = null;
          element.parent_value = null;
        }
      });
      tempElements = tempElements.filter((element) => element.element_id !== id);
      return tempElements;
    });
    setShowDeleteModal(false);
  };

  return (
    <div className='mt-16 template-section'>
      <AddElementModal
        handleSave={handleAddElement}
        open={isElementModalOpen}
        setOpen={setIsElementModalOpen}
      />
      <div className='d-flex justify-end mt-8 btn-edit'>
        {props?.details?.sections?.[props.currentSection]?.groups?.length ? (
          <Button
            variant='outlined'
            color='primary'
            onClick={() => {
              // need to uncomment below line if need to bypass bank account related logic
              // handleAddElement('regular);
              setIsElementModalOpen(true);
            }}
            className='mb-8'
          >
            + Add
          </Button>
        ) : (
          ''
        )}
      </div>
      {formElements?.length ? (
        <StaticPaginationTable
          columns={columns}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          data={formElements?.length ? formElements : []}
          rowsPerPageOptions={rowsPerPageOptions}
          toSort={true}
          handleSort={handleDrag}
          page={page}
          setPage={setPage}
        />
      ) : (
        ''
      )}

      <ManageOptionsDrawer
        visible={isOptionDrawerVisible}
        setVisible={setOptionsDrawerVisible}
        options={allElements?.[currentIndex]?.options}
        currentIndex={currentIndex}
        setAllElements={setAllElements}
      />
      <FormPreview open={isPreviewModalOpen} setOpen={setPreviewModalOpen} data={props.details} />
      <DeleteModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        item={'element'}
        submitFunction={deleteHandler}
      />
      <FieldConfigurationDrawer
        visible={isConfigDrawerOpen}
        setVisible={setConfigDrawerOpen}
        allElements={allElements}
        setAllElements={setAllElements}
        currentIndex={currentIndex}
      />
    </div>
  );
});
export default CreateQuestionsForm;
