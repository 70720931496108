import { useEffect, useMemo, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Alert, Box, Button, CardContent, Grid, MenuItem, Select, Typography } from '@mui/material';
import Table from '../../../components/common/Table';
import EditIcon from '@mui/icons-material/Edit';
import Loader from '../../../components/common/Loader';
import { getClient } from '../../../services/admin/clients';
import ActionMenu from '../../../components/common/ActionMenu';
import { ADMIN_ROLE, SUPER_ADMIN_ROLE } from '../../../utils/constants';
import { addOrderType, editOrderType, getOrderTypes } from '../../../services/admin/configurations';
import AddEditOrderTypeModal from '../../../components/admin/configurations/AddEditOrderType';
import { toast } from 'react-toastify';

const OrderTypes = () => {
  const { pathname } = useLocation();
  const { client_id } = useParams();
  const { admin } = useSelector((state) => state?.authSlice);
  const isAdmin =
    admin?.user_role_name === SUPER_ADMIN_ROLE || admin?.user_role_name === ADMIN_ROLE
      ? true
      : false;
  const [loading, setLoading] = useState(false);
  const [clientLoading, setClientLoading] = useState(false);
  const [orderTypes, setOrderTypes] = useState([]);
  const [record, setRecord] = useState({});
  const [perPageRecords, setPerPageRecords] = useState(100);
  const [total, setTotal] = useState(0);
  const [client, setClient] = useState({});
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [offices, setOffices] = useState([]);
  const [currPage, setCurrPage] = useState(0);
  const [open, setOpen] = useState(false);
  const clientId = client_id || admin?.client_id || '';

  useEffect(() => {
    if (client_id || admin?.client_id) fetchClient();
  }, [client_id]);

  useEffect(() => {
    if (isAdmin || selectedOffice) {
      fetchOrderTypes();
    }
  }, [currPage, perPageRecords, pathname, selectedOffice]);

  useEffect(() => {
    if (offices?.length) {
      if (offices?.length > 1) {
        let primaryOffice = offices.find((office) => office.is_primary);
        if (primaryOffice && (isAdmin ? (clientId ? true : false) : true))
          setSelectedOffice(primaryOffice?.client_office_id);
      } else {
        if (isAdmin ? (clientId ? true : false) : true) {
          setSelectedOffice(offices?.[0]?.client_office_id);
        }
      }
    }
  }, [offices]);

  const fetchClient = async () => {
    setClientLoading(true);
    const res = await getClient(client_id || admin?.client_id, admin?.token);
    if (res.success) {
      setClient(res.response);
      if (res?.response?.offices?.length) {
        setOffices(res.response.offices || []);
      }
    }
    setClientLoading(false);
  };

  const tableData = useMemo(() => {
    if (orderTypes?.length) {
      return orderTypes?.map((oType) => {
        return {
          ...oType,
          id: oType?.order_type_id || '-',
          createdBy: oType?.created_by_user || '-',
          orderType: oType?.order_type_name || '-',
          orderTypeStatus: () => (
            <div
              className={`${
                oType?.order_type_status !== 'inactive'
                  ? 'color-p text-capitalize'
                  : 'error text-capitalize'
              }`}
            >
              {oType?.order_type_status ?? ''}
            </div>
          ),
          actions: (props) => (
            <div>
              <ActionMenu
                actions={[
                  {
                    label: 'Edit',
                    logo: <EditIcon className='action-menu-item' />,
                    onClick: () => {
                      setRecord(oType);
                      setOpen(true);
                    },
                  },
                ]}
              />
            </div>
          ),
        };
      });
    }
    return [];
  }, [orderTypes]);

  const fetchOrderTypes = async () => {
    setLoading(true);
    const res = await getOrderTypes(
      admin?.token,
      clientId,
      selectedOffice,
      currPage + 1,
      perPageRecords
    );
    if (res?.success) {
      setOrderTypes(res?.response?.data || []);
      setTotal(res?.response?.meta?.total);
    } else {
      setOrderTypes([]);
      setTotal(0);
    }
    setLoading(false);
  };

  const columns = [
    { id: 'actions', label: 'Actions', type: 'jsx' },
    // { id: 'id', label: 'ID' },
    { id: 'orderType', label: 'Order Type' },
    { id: 'orderTypeStatus', label: 'Status', type: 'jsx' },
    // { id: 'orderType', label: 'Order Type' },
    // { id: 'createdBy', label: 'Created By' },
  ];

  const onRowClick = (row) => {
    setRecord(row);
    setOpen(true);
  };

  const handleOrderTypeAdd = async (data) => {
    setLoading(true);
    let payload = { ...data };
    if (client_id || admin?.client_id) payload.client_id = client_id || admin?.client_id;
    if (selectedOffice) payload.client_office_id = selectedOffice;
    const res = await addOrderType(admin?.token, payload);
    if (res?.success) {
      toast.success(res?.message);
      fetchOrderTypes();
      setOpen(false);
      setRecord(null);
    } else {
      toast.error(res?.message);
    }
    setLoading(false);
  };
  const handleOrderTypeEdit = async (data) => {
    setLoading(true);
    let payload = { ...data };
    if (client_id || admin?.client_id) payload.client_id = client_id || admin?.client_id;
    if (selectedOffice) payload.client_office_id = selectedOffice;
    const res = await editOrderType(admin?.token, payload, record?.order_type_id);
    if (res?.success) {
      toast.success(res?.message);
      fetchOrderTypes();

      setOpen(false);
      setRecord(null);
    } else {
      toast.error(res?.message);
    }
    setLoading(false);
  };
  return (
    <Box className='dashboard-main'>
      <AddEditOrderTypeModal
        open={open}
        setOpen={setOpen}
        record={record}
        setRecord={setRecord}
        onSave={Object.keys(record || {})?.length ? handleOrderTypeEdit : handleOrderTypeAdd}
      />
      {(loading || clientLoading) && <Loader />}
      <Grid container className='dashboard-content'>
        <Grid item md={12}>
          <div className='transaction-logs-wrapper'>
            <CardContent>
              <Grid item md={12} className='d-flex justify-between align-center'>
                <Typography variant='h4' className='mb-0'>
                  {client_id && client?.company_name
                    ? `Order Types for ${client?.company_name}`
                    : isAdmin
                    ? 'Order Types'
                    : 'Order Types'}
                </Typography>
                <div className='flex-row'>
                  {!client?.is_form_template_same_for_all_offices && offices?.length > 1 ? (
                    <div className='mr-12 table-select-filter'>
                      <label id='demo-simple-select-label'>Office : </label>
                      <Select
                        sx={{
                          // Reset common styles
                          marginLeft: '4px',
                          width: '200px',
                          // Add any additional styles you need
                        }}
                        defaultValue={selectedOffice}
                        displayEmpty={true}
                        value={selectedOffice}
                        onChange={(e) => {
                          setSelectedOffice(e.target.value);
                        }}
                      >
                        {offices?.map((office) => (
                          <MenuItem value={office.client_office_id} key={office.client_office_id}>
                            {office.office_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className='ml-4'>
                    {' '}
                    <Button
                      autoFocus
                      onClick={() => {
                        setOpen(true);
                      }}
                      variant='contained'
                      color='primary'
                      style={{ padding: '4px 12px' }}
                    >
                      + Add Order Type
                    </Button>
                  </div>
                </div>
              </Grid>
            </CardContent>
            <Box>
              <CardContent>
                {client?.is_form_template_same_for_all_offices ? (
                  <Alert
                    severity='info'
                    className='f-13 mb-8 fw-450 '
                    style={{ padding: '2px 10px' }}
                  >
                    All offices within your client follow the same participant templates. Therefore,
                    if you add or update order types, the changes will be reflected for all offices
                    within your client.
                  </Alert>
                ) : (
                  ''
                )}
                <Table
                  loader={loading}
                  columns={columns}
                  data={tableData}
                  count={total}
                  current_page={currPage}
                  perPageRecord={perPageRecords}
                  setPerPageRecords={setPerPageRecords}
                  setCurrPage={setCurrPage}
                  isClickable={true}
                  onRowClick={onRowClick}
                />
              </CardContent>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};
export default OrderTypes;
