import React, { useEffect, useState } from 'react';
import {
  Drawer,
  TextField,
  IconButton,
  Grid,
  Typography,
  Divider,
  Button,
  Switch,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { arrayMoveImmutable } from 'array-move';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { styled } from '@mui/material/styles';
import { randomUUID } from '../../../utils/helpers';
import DeleteModal from '../../common/DeleteModal';
import { useForm } from 'react-hook-form';
import { Close } from '@mui/icons-material';
import { cloneDeep } from 'lodash';

const ManageSectionDrawer = (props) => {
  const [sections, setSections] = useState(props?.sections || []);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [id, setId] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setSections(cloneDeep(props.sections));
  }, [props.sections]);

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  }));

  const handleSectionChange = (e, index) => {
    let tempSections = [...sections];
    tempSections[index].section_label = e.target.value;
    setSections(tempSections);
  };
  const handleSectionHideChange = (checked, index) => {
    let tempSections = [...sections];
    tempSections[index].is_hidden = checked;
    setSections(tempSections);
  };
  const handleSections = () => {
    setSections((sections) => [
      ...sections,
      {
        section_id: randomUUID(),
        section_label: '',
        groups: [],
      },
    ]);
  };

  const handleDrag = (result) => {
    let tempSections = [...sections];
    if (tempSections.length > 1) {
      tempSections = arrayMoveImmutable(
        tempSections,
        result.source.index,
        result.destination.index
      );
      setSections(tempSections);
    }
  };

  const handleDeleteSection = () => {
    setSections((sections) => sections.filter((section) => section.section_id !== id));
    setId('');
    setShowDeleteModal(false);
  };

  const handleSave = (data) => {
    props.setData({ sections });
    props.setCurrentGroup(0);
    props.setCurrentSection(0);
    props.setVisible(false);
  };

  const isUnique = (list, id, name) => {
    const filteredOptions = list?.filter((option) => option?.section_id !== id);
    return !filteredOptions?.some((option) => option?.section_label === name);
  };
  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Drawer
        sx={{
          width: 500,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 500,
            boxSizing: 'border-box',
          },
        }}
        anchor='right'
        open={props.visible}
        onClose={() => {
          setSections(cloneDeep(props.sections));
          props.setVisible(false);
        }}
      >
        <DrawerHeader>
          <Typography variant='h6'>Update Sections</Typography>
          <Close
            size={20}
            sx={{ position: 'absolute', right: '10px', top: '20px', color: 'grey' }}
            fontSize='small'
            onClick={() => {
              setSections(cloneDeep(props.sections));
              props.setVisible(false);
            }}
            className='cursor-pointer'
          />
        </DrawerHeader>
        <Divider />
        <form onSubmit={handleSubmit(handleSave)} className='drawer-form'>
          <Droppable droppableId='test' className='droppable-container'>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className='droppable-container'
                style={{ overflowY: 'scroll' }}
              >
                <Grid container spacing={2} className='mt-4 f-12 fw-550'>
                  <Grid item className='flex-center' md={1}></Grid>
                  <Grid item md={5}>
                    Section Name
                  </Grid>
                  <Grid item md={4}>
                    Included in Participant Form
                  </Grid>
                  <Grid item md={2}>
                    Remove
                  </Grid>
                </Grid>
                {sections?.map((section, index) => (
                  <Draggable
                    draggableId={`${section.section_id}`}
                    index={index}
                    key={`${section.section_id}`}
                  >
                    {(provided, snapshot) => (
                      <Grid
                        container
                        spacing={2}
                        ref={provided.innerRef}
                        key={`${section.section_id}`}
                        className='mt-0'
                        {...provided.draggableProps}
                      >
                        <Grid item className='flex-center' md={1}>
                          <div
                            {...provided.dragHandleProps}
                            className='drag-icon'
                            style={{ cursor: 'grab' }}
                            key={`${section.section_id}`}
                          >
                            <DragIndicatorIcon fontSize='small' />
                          </div>
                        </Grid>
                        <Grid item md={5}>
                          <TextField
                            id='outlined-basic'
                            name={section?.section_id}
                            value={section?.section_label}
                            className='full-width'
                            variant='outlined'
                            inputProps={{
                              role: 'presentation',
                              autoComplete: 'off',
                            }}
                            {...register(section?.section_id, {
                              required: true,
                              validate: {
                                isUnique: (value) => isUnique(sections, section.section_id, value),
                              },
                            })}
                            // style={{ width: '280px' }}
                            onChange={(e) => handleSectionChange(e, index)}
                          />
                          {errors[section?.section_id] && (
                            <span className='error' style={{ fontSize: '13px' }}>
                              {errors[section?.section_id]?.type === 'isUnique'
                                ? 'Section label must be unique'
                                : 'Section label is required'}
                            </span>
                          )}
                        </Grid>
                        <Grid item md={4}>
                          <Switch
                            checked={section?.is_hidden ? false : true}
                            onChange={(e) => {
                              handleSectionHideChange(!e.target.checked, index);
                            }}
                            color='primary'
                            size='small'
                          />
                        </Grid>
                        <Grid item md={2}>
                          <IconButton
                            className='icon-btn'
                            onClick={() => {
                              setId(section.section_id);
                              setShowDeleteModal(true);
                            }}
                          >
                            <DeleteIcon color='error' fontSize='small' />
                          </IconButton>
                          {index === sections.length - 1 && (
                            <IconButton
                              className='icon-btn'
                              onClick={() => {
                                handleSections();
                              }}
                            >
                              <AddIcon color='primary' fontSize='small' />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>

          <div className='fixed-footer' style={{ width: '500px' }}>
            <Divider className='mt-20' />
            <Grid item className='p-8 d-flex justify-end mr-8'>
              {/* <Button
              variant='outlined'
              className='p-4'
              onClick={() => {
                setSections(props.sections);
                props.setVisible(false);
              }}
            >
              Close
            </Button> */}
              {!sections?.length && (
                <Button
                  variant='outlined'
                  className='p-4 ml-8'
                  onClick={() => {
                    handleSections();
                  }}
                >
                  + Add
                </Button>
              )}
              <Button variant='contained' type='submit' className='ml-8 p-4'>
                Save
              </Button>
            </Grid>
          </div>
        </form>
      </Drawer>
      <DeleteModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        item={'section'}
        submitFunction={handleDeleteSection}
      />
    </DragDropContext>
  );
};

export default ManageSectionDrawer;
