// breadcrumbSlice.js
import { createSlice } from '@reduxjs/toolkit';

const breadcrumbSlice = createSlice({
  name: 'breadcrumb',
  initialState: { isMenuOpen: true, dd1Open: true, dd2Open: true, dd3Open: true },
  reducers: {
    setBreadcrumb: (state, action) => {
      if (Array.isArray(state)) {
        state = {};
      }
      state[action.payload.key] = action.payload.data;
    },
    resetBreadcrumb: (state) => {
      state = {};
    },
    toggleMenuCollapsed: (state, action) => {
      state.isMenuOpen = action.payload.open;
    },
    setDDOpen: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

export const { setBreadcrumb, resetBreadcrumb, toggleMenuCollapsed, setDDOpen } =
  breadcrumbSlice.actions;
export default breadcrumbSlice.reducer;
