import { authorizedApi } from '../../utils/api';

export const getList = (token, str) => {
  return authorizedApi(token).get(`/admin/logs?${str}`);
};

export const getDetails = (token, id) => {
  return authorizedApi(token).get(`/admin/logs/${id}`);
};

export const getUrl = (token, id, key) => {
  return authorizedApi(token).get(`/admin/logs/response/file/${id}/${key}`);
};
