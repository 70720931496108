import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  FormControl,
  Typography,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import { useForm, Controller } from 'react-hook-form';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    width: '500px',
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const AddEditOrderTypeModal = ({ open, setOpen, record, onSave, setRecord }) => {
  useEffect(() => {
    if (open) {
      record
        ? reset({ name: record?.order_type_name, status: record?.order_type_status })
        : reset({});
    }
  }, [record, open]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleClose = () => {
    setRecord(null);
    reset({});
    setOpen(false);
  };

  const handleSave = async (data) => {
    onSave(data);
    reset({});
  };

  return (
    <div>
      <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id='customized-dialog-title'
          className='customized-dialog-title'
          style={{ fontSize: '16px' }}
        >
          Order Type
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <Box component='form' onSubmit={handleSubmit(handleSave)}>
            <div>
              <label className='f-13'>Order Type</label>
              <label className='required'>{'*'}</label>
              <Controller
                name={`name`}
                control={control}
                rules={{ required: 'Order type is required' }}
                defaultValue=''
                render={({ field, fieldState }) => (
                  <FormControl fullWidth>
                    <TextField {...field}></TextField>
                    <Typography className='order-error f-12' color='error'>
                      {fieldState.error ? fieldState.error.message : ''}
                    </Typography>
                  </FormControl>
                )}
              />
            </div>
            <div className='mt-8'>
              <label className='mt-8'>Status</label>
              <label className='required'>{'*'}</label>
              <Controller
                name='status'
                control={control}
                defaultValue='active'
                render={({ field }) => (
                  <FormControl fullWidth variant='outlined' error={!!errors.status}>
                    <Select {...field}>
                      <MenuItem value='active'>Active</MenuItem>
                      <MenuItem value='inactive'>Inactive</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </div>
            <DialogActions className='mt-8'>
              <Button
                autoFocus
                onClick={handleClose}
                className='p-2'
                color='secondary'
                style={{ color: 'grey' }}
              >
                Close
              </Button>
              <Button
                autoFocus
                type='submit'
                variant='contained'
                color='primary'
                className='p-2 btn-participentType'
              >
                Save
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};
export default AddEditOrderTypeModal;
