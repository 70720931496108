import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  FormControl,
  Typography,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import { useForm, Controller } from 'react-hook-form';
import Loader from '../common/Loader';
import { updateAdditionalParticipant, updateParticipant } from '../../services/order/order';
import { toast } from 'react-toastify';
import { resendLink } from '../../services/admin/participantForms';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    width: '500px',
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const AssignModal = ({ open, setOpen, record, token, fetchOrder, orderId }) => {
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (open) {
      reset({ email: record?.email });
    }
  }, [record, open]);

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const handleSave = async (data) => {
    setLoading(true);
    if (record?.email !== data?.email) {
      if (record?.isAdditional) {
        let body = {
          order_participant_id: record?.participant_id,
          email: data?.email,
        };
        const res = await updateAdditionalParticipant(body, token);
        if (!res?.success) {
          setLoading(false);
          return toast.error(res.message);
        }
      } else {
        let body = {
          first_name: record?.first_name,
          last_name: record?.last_name,
          email: data?.email,
          mobile_phone: record?.mobile_phone,
          order_id: orderId,
        };
        const res = await updateParticipant(record?.participant_id, body, token);
        if (!res?.success) {
          setLoading(false);
          return toast.error(res.message);
        }
      }
    }
    const res = await resendLink(
      { participant_uuid: record?.uuid, order_participant_id: record?.participant_id },
      token
    );
    if (res?.success) {
      setOpen(false);
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
    setTimeout(() => {
      fetchOrder();
      setLoading(false);
    }, 3000);
  };

  return (
    <div>
      {loading && <Loader />}
      <BootstrapDialog onClose={handleClose} aria-labelledby='customized-dialog-title' open={open}>
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id='customized-dialog-title'
          className='customized-dialog-title'
          style={{ fontSize: '16px' }}
        >
          Resend Email
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <div className='f-12 mt-8 fw-450 mb-8 color-g'>
            <b>Note:</b> An email will be sent to the address shown below with a link to the
            participant form to participant. You can update the email address if it is incorrect.
          </div>
          <Box component='form' onSubmit={handleSubmit(handleSave)}>
            <Controller
              name={`email`}
              control={control}
              rules={{
                required: 'Email is required',
                pattern: {
                  value: /^\S+@\S+\.\S+$/,
                  message: 'Invalid email format',
                },
              }}
              defaultValue=''
              render={({ field, fieldState }) => (
                <FormControl fullWidth>
                  <TextField {...field}></TextField>
                  <Typography className='order-error f-12' color='error'>
                    {fieldState.error ? fieldState.error.message : ''}
                  </Typography>
                </FormControl>
              )}
            />

            <DialogActions className='mt-8'>
              <Button
                autoFocus
                onClick={handleClose}
                className='p-2'
                color='secondary'
                style={{ color: 'grey' }}
              >
                Close
              </Button>
              <Button
                autoFocus
                type='submit'
                variant='contained'
                color='primary'
                className='p-2 btn-participentType'
              >
                Send
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};
export default AssignModal;
