import { authorizedApi } from '../../utils/api';

export const getOrderList = (token, CurrPage = 1, per_page = 10) => {
  return authorizedApi(token).get(`/orders?page=${CurrPage}&per_page=${per_page}&is_listing=yes`);
};

export const getOrders = (token, paramString) => {
  return authorizedApi(token).get(`/orders?${paramString}`);
};

export const getSSN = (data, token) => {
  return authorizedApi(token).post(`/fetch/ssn`, data);
};

export const getOrderTypes = (token, clientId, officeId) => {
  return authorizedApi(token).get(
    `/admin/orders/types?client_id=${clientId}&client_office_id=${officeId}`
  );
};

export const getOrder = (id, token) => {
  return authorizedApi(token).get(`/admin/orders/${id}`);
};

export const getOrderLabel = (companyId, token) => {
  return authorizedApi(token).get(`/order/orders/labels/${companyId}`);
};

export const addOrder = (data, token) => {
  return authorizedApi(token).post(`/admin/orders`, data);
};

export const archiveOrder = (data, token) => {
  return authorizedApi(token).post(`/admin/order/change/record/status`, data);
};

export const updateOrder = (id, data, token) => {
  return authorizedApi(token).put(`/admin/orders/${id}`, data);
};

export const addParticipants = (data, token) => {
  return authorizedApi(token).post(`/admin/add/participant`, data);
};

export const updateParticipant = (id, data, token) => {
  return authorizedApi(token).put(`/admin/update/participant/${id}`, data);
};
export const updateAdditionalParticipant = (data, token) => {
  return authorizedApi(token).post(`/update/additional/participant`, data);
};

export const getClientList = (token) => {
  return authorizedApi(token).get(`/admin/get/clients`);
};

export const getPropertyDetails = (data, token) => {
  return authorizedApi(token).get(`/order/property/detail/source`, { params: data });
};

export const getUsersOptions = (data, token) => {
  return authorizedApi(token).get(`/admin/get/client/users`, { params: data });
};

export const reassignOrder = (data, token) => {
  return authorizedApi(token).post(`/admin/reassign/order`, data);
};

export const getClientUserByClientId = (id, token) => {
  return authorizedApi(token).get(`/admin/client/users/${id}`);
};

export const fetchLinkStatus = (uuid, orderId, token) => {
  return authorizedApi(token).get(
    `/admin/participant/source/pull?participant_uuid=${uuid}&order_id=${orderId}`
  );
};

export const searchPropertyDetails = (token, data) => {
  return authorizedApi(token).post(`/admin/property/detail`, data);
};

export const createOrderFromCSV = (data, config, token) => {
  return authorizedApi(token).post(`/import/orders`, data, config);
};

export const manualVerification = (data, token) => {
  return authorizedApi(token).post(`/participant/id/manual/verify`, data);
};