import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CardContent, Chip, Grid, Typography } from '@mui/material';
import Table from '../../../components/common/Table';
import {
  Edit,
  Visibility,
  PersonAdd,
  Group,
  ListAlt,
  Settings,
  Article,
  DensitySmall,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { getClients, deleteClient } from '../../../services/admin/clients';
import Loader from '../../../components/common/Loader';
import { toast } from 'react-toastify';
import DeleteModal from '../../../components/common/DeleteModal';
import ActionMenu from '../../../components/common/ActionMenu';

const Clients = () => {
  const navigate = useNavigate();
  const { admin } = useSelector((state) => state?.authSlice);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [perPageRecords, setPerPageRecords] = useState(100);
  const [id, setId] = useState('');
  const [clientList, setClientList] = useState([]);
  const [total, setTotal] = useState(0);
  const [currPage, setCurrPage] = useState(0);

  const tableData = useMemo(() => {
    if (clientList?.length) {
      return clientList?.map((client) => {
        return {
          ...client,
          client_name: client?.offices?.find((ofc) => ofc.is_primary)?.contact_name,
          email: client?.offices?.find((ofc) => ofc.is_primary)?.contact_email,
          // status: () => (
          //   <div>
          //     <Chip
          //       label={client?.offices?.find((ofc) => ofc.is_primary)?.status}
          //       style={{
          //         height: '20px',
          //         padding: '2px',
          //         borderRadius: '5px',
          //         textTransform: 'capitalize',
          //       }}
          //       className={`${
          //         client?.offices?.find((ofc) => ofc.is_primary)?.status !== 'disabled'
          //           ? 'bg-p'
          //           : 'bg-r'
          //       }`}
          //     />
          //   </div>
          // ),
          status: () => (
            <div
              className={`${
                client?.offices?.find((ofc) => ofc.is_primary)?.status !== 'inactive'
                  ? 'color-p text-capitalize'
                  : 'error text-capitalize'
              }`}
            >
              {client?.offices?.find((ofc) => ofc.is_primary)?.status ?? ''}
            </div>
          ),
          actions: (props) => (
            <div>
              <ActionMenu
                actions={[
                  {
                    label: 'Edit Client',
                    logo: <Edit className='action-menu-item' />,
                    onClick: () => navigate(`/admin/clients/${props.client_id}`),
                  },
                  {
                    label: 'View Client',
                    logo: <Visibility className='action-menu-item' />,
                    onClick: () => navigate(`/admin/clients/${props.client_id}/view`),
                  },
                  {
                    label: 'Add User',
                    logo: <Group className='action-menu-item' />,
                    onClick: () => navigate(`/admin/users/add?client_id=${props.client_id}`),
                  },
                  {
                    label: `Client's Preference`,
                    logo: <Settings className='action-menu-item' />,
                    onClick: () => navigate(`/admin/clients/configurations/${props.client_id}`),
                  },
                  {
                    label: `Client's Participant Form Configuration`,
                    logo: <ListAlt className='action-menu-item' />,
                    onClick: () => navigate(`/admin/clients/templates/${props.client_id}`),
                  },
                  {
                    label: `Client's Document Configuration`,
                    logo: <Article className='action-menu-item' />,
                    onClick: () =>
                      navigate(`/admin/clients/document-configurations/${props.client_id}`),
                  },
                  {
                    label: `Client's Order Type`,
                    logo: <DensitySmall className='action-menu-item' />,
                    onClick: () => navigate(`/admin/clients/order-types/${props.client_id}`),
                  },
                ]}
              />
            </div>
          ),
        };
      });
    }
    return [];
  }, [clientList]);

  useEffect(() => {
    fetchClientList();
  }, [currPage, perPageRecords]);

  const fetchClientList = async () => {
    setLoading(true);
    const res = await getClients(admin?.token, currPage + 1, perPageRecords);
    if (res?.success) {
      setClientList(res?.response?.data || []);
      setTotal(res?.response?.meta?.total);
    } else {
      toast.error(res?.message);
    }
    setLoading(false);
  };
  const columns = [
    { id: 'actions', label: 'Actions', type: 'jsx' },
    { id: 'company_name', label: 'Company Name' },
    { id: 'client_name', label: 'Contact Name' },
    { id: 'email', label: 'Contact Email' },
    { id: 'domain', label: 'Domain' },
    { id: 'status', label: 'Status', type: 'jsx' },
  ];

  const deleteHandler = async () => {
    setLoading(true);
    const res = await deleteClient(id, admin?.token);
    if (res.success) {
      fetchClientList();
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
    setShowDeleteModal(false);
    setLoading(false);
  };

  const onRowClick = (row) => {
    navigate(`/admin/clients/${row.client_id}`);
  };
  return (
    <Box className='dashboard-main'>
      {loading && <Loader />}
      <Grid container className='dashboard-content'>
        <Grid item md={12}>
          <div className='transaction-logs-wrapper'>
            <CardContent>
              <Grid item md={12} className='d-flex justify-between align-center'>
                <Typography variant='h4' className='mb-0'>
                  Client Offices
                </Typography>
                <Button
                  autoFocus
                  onClick={() => {
                    navigate('/admin/clients/add');
                  }}
                  variant='contained'
                  color='primary'
                  style={{ padding: '4px 12px' }}
                >
                  <PersonAdd fontSize='small' className='mr-8' /> ADD Client
                </Button>
              </Grid>
            </CardContent>
            <Box>
              <CardContent>
                <Table
                  loader={loading}
                  columns={columns}
                  data={tableData}
                  count={total}
                  current_page={currPage}
                  perPageRecord={perPageRecords}
                  setPerPageRecords={setPerPageRecords}
                  setCurrPage={setCurrPage}
                  isClickable={true}
                  onRowClick={onRowClick}
                />
              </CardContent>
            </Box>
          </div>
        </Grid>
      </Grid>
      <DeleteModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        item={'Client'}
        submitFunction={deleteHandler}
      />
    </Box>
  );
};
export default Clients;
