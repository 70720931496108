import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Drawer,
  Typography,
  Divider,
  Grid,
  Accordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails,
  Tabs,
  Tab,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close, ExpandMore } from '@mui/icons-material';
import ReactJson from 'react-json-view';
import { getDetails, getUrl } from '../../../services/admin/integrationLogs';
import { toast } from 'react-toastify';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
  backgroundColor: '#ecf3f9a3',
  // flexDirection: 'row-reverse',

  '& .MuiAccordionSummary-content': {
    // marginLeft: theme.spacing(1),
    fontSize: '13px',
    fontWeight: '500',
  },
}));

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const LogDetailsDrawer = (props) => {
  const { admin } = useSelector((state) => state?.authSlice);
  const [logDetails, setLogDetails] = useState({});
  const [value, setValue] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [mainTabValue, setMainTabValue] = useState(0);

  const handleChange = (val, index) => {
    setValue((prev) => {
      let prevState = [...prev];
      prevState[index] = val;
      return prevState;
    });
  };
  useEffect(() => {
    if (props?.curRecord?.integration_log_id) {
      getLogDetails();
    }
  }, [props?.curRecord?.integration_log_id]);

  const getLogDetails = async () => {
    props?.setLoading(true);
    const res = await getDetails(admin?.token, props?.curRecord?.integration_log_id);
    if (res?.success) {
      let tempLogDetails = res?.response;
      if (tempLogDetails?.summaries?.length) {
        for (const [index, sum] of Object.entries(res?.response?.summaries)) {
          let jsonRes = JSON.parse(sum?.response || {});
          for (const key of Object.keys(jsonRes?.files || {})) {
            if (key.startsWith('img')) jsonRes.files[key] = 'Click to see associated image';
            if (key.startsWith('content')) jsonRes.files[key] = 'Click to see associated content';
          }
          tempLogDetails.summaries[index].response = JSON.stringify(jsonRes);
        }
      }
      setLogDetails(tempLogDetails);
    }
    props?.setLoading(false);
  };

  const handleImages = async (val, id) => {
    props?.setLoading(true);
    const res = await getUrl(admin?.token, id, val?.name);
    if (res?.success) {
      if (res?.response?.url) window.open(res?.response?.url);
    } else {
      toast.error(res?.message);
    }
    props?.setLoading(false);
  };

  return (
    <Drawer
      sx={{
        width: 900,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 900,
          boxSizing: 'border-box',
        },
      }}
      anchor='right'
      open={props.visible}
      onClose={() => {
        props.setVisible(false);
      }}
    >
      <DrawerHeader>
        <Typography variant='h6'>Log Details</Typography>
        <Close
          sx={{ position: 'absolute', right: '10px', top: '20px', color: 'grey' }}
          fontSize='small'
          onClick={() => {
            props.setVisible(false);
          }}
          className='cursor-pointer'
        />
      </DrawerHeader>
      <Divider />

      <div className='p-16'>
        {' '}
        <Grid container rowGap={2} className='mb-8'>
          <Grid item xs={8} sm={8}>
            <Grid container rowGap={1}>
              <Grid item xs={12} sm={12} className='color-p fw-550 f-16 mb-8'>
                API Request Details
              </Grid>
              <Grid item xs={2} sm={2} className='f-13 fw-550'>
                Endpoint:
              </Grid>
              <Grid item xs={10} sm={10} className='f-13 color-g'>
                {logDetails?.api_endpoint ?? '-'}
              </Grid>
              <Grid item xs={2} sm={2} className='f-13 fw-550'>
                Method:
              </Grid>
              <Grid item xs={10} sm={10} className='f-13 color-g'>
                {logDetails?.method ?? '-'}
              </Grid>
              <Grid item xs={2} sm={2} className='f-13 fw-550'>
                Status:
              </Grid>
              <Grid item xs={10} sm={10} className='f-13 color-g'>
                {logDetails?.status ?? '-'}
              </Grid>
              <Grid item xs={2} sm={2} className='f-13 fw-550'>
                Duration:
              </Grid>
              <Grid item xs={10} sm={10} className='f-13 color-g'>
                {logDetails?.duration ?? '-'}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} sm={4}>
            {' '}
            <Grid container rowGap={1}>
              <Grid item xs={12} sm={12} className='color-p fw-550 f-16 mb-8'>
                Log Details
              </Grid>
              <Grid item xs={4} sm={4} className='f-13 fw-550'>
                Purpose:
              </Grid>
              <Grid item xs={8} sm={8} className='f-13 color-g'>
                {logDetails?.purpose ?? '-'}
              </Grid>
              <Grid item xs={4} sm={4} className='f-13 fw-550'>
                Escrow No:
              </Grid>
              <Grid item xs={8} sm={8} className='f-13 color-g'>
                {logDetails?.escrow_num ?? '-'}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className='color-p fw-550 f-16 mt-16 mb-8'>
          {' '}
          <Tabs
            value={mainTabValue}
            onChange={(_, val) => {
              setMainTabValue(val);
            }}
            aria-label='basic tabs example'
          >
            <Tab label='Headers' {...a11yProps(0)} />
            <Tab label='Payload' {...a11yProps(1)} />
            <Tab label='Response' {...a11yProps(2)} />
          </Tabs>
          <CustomTabPanel value={mainTabValue} index={0}>
            <ReactJson
              key={0}
              src={JSON.parse(logDetails?.headers ?? '{}')}
              theme='monokai'
              displayDataTypes={false}
            />
          </CustomTabPanel>
          <CustomTabPanel value={mainTabValue} index={1}>
            <ReactJson
              key={1}
              src={JSON.parse(logDetails?.payload ?? '{}')}
              theme='monokai'
              displayDataTypes={false}
            />
          </CustomTabPanel>
          <CustomTabPanel value={mainTabValue} index={2}>
            <ReactJson
              key={2}
              src={JSON.parse(logDetails?.response ?? '{}')}
              theme='monokai'
              displayDataTypes={false}
            />
          </CustomTabPanel>
        </div>
        <div className='color-p fw-550 f-16 mt-16 mb-8'>API Summery</div>
        <div>
          {logDetails?.summaries?.map((log, index) => {
            return (
              <Accordion defaultExpanded={false}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls='panel1-content'
                  id='panel1-header'
                >
                  {log?.url}
                </AccordionSummary>
                <AccordionDetails>
                  <Tabs
                    value={value?.[index]}
                    onChange={(_, val) => {
                      handleChange(val, index);
                    }}
                    aria-label='basic tabs example'
                  >
                    <Tab label='Headers' {...a11yProps(0)} />
                    <Tab label='Payload' {...a11yProps(1)} />
                    <Tab label='Response' {...a11yProps(2)} />
                  </Tabs>
                  <CustomTabPanel value={value?.[index]} index={0}>
                    <ReactJson
                      key={0}
                      src={JSON.parse(log?.headers ?? {})}
                      theme='monokai'
                      displayDataTypes={false}
                    />
                  </CustomTabPanel>
                  <CustomTabPanel value={value?.[index]} index={1}>
                    <ReactJson
                      key={1}
                      src={JSON.parse(log?.payload ?? {})}
                      theme='monokai'
                      displayDataTypes={false}
                    />
                  </CustomTabPanel>
                  <CustomTabPanel value={value?.[index]} index={2}>
                    <ReactJson
                      key={2}
                      src={JSON.parse(log?.response ?? {})}
                      theme='monokai'
                      onSelect={(val) => {
                        handleImages(val, log?.id);
                      }}
                      displayDataTypes={false}
                    />
                  </CustomTabPanel>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>
    </Drawer>
  );
};

export default LogDetailsDrawer;
