import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Divider } from '@mui/material';

const CommonTable = ({
  columns,
  data,
  loader,
  toSort,
  rowClassNames = () => {
    return '';
  },
  isClickable = false,
  onRowClick = () => {},
}) => {
  return (
    <div>
      <div className='table-div'>
        <Table style={{ width: '100%', overflow: 'scroll' }}>
          <TableHead>
            <TableRow>
              {toSort && <TableCell key={1111}>{'Sort'}</TableCell>}

              {columns.map((column) => {
                if (column?.render) {
                  return (
                    <TableCell key={column.id} style={{ padding: '12px' }}>
                      {column['render'](column)}
                    </TableCell>
                  );
                } else {
                  return <TableCell key={column.id}>{column.label}</TableCell>;
                }
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {!data?.length && !loader ? (
              <TableRow>
                <TableCell colSpan={columns?.length || 1} className='no-record'>
                  No records found.
                </TableCell>
              </TableRow>
            ) : (
              data?.map((group, groupIndex) => (
                <>
                  {/* Render Group Title */}
                  <TableRow key={`group-title-${groupIndex}`} className='group-title'>
                    <TableCell colSpan={(columns?.length || 0) + (toSort ? 1 : 0)} align='center'>
                      <Divider>{group?.title || 'Untitled Group'}</Divider>
                    </TableCell>
                  </TableRow>

                  {/* Render Group Records */}
                  {group?.records?.map((row, index) => (
                    <TableRow
                      key={row?.element_id || `row-${groupIndex}-${index}`}
                      className={isClickable ? 'cursor-pointer' : ''}
                      onDoubleClick={() => onRowClick?.(row)}
                    >
                      {columns?.map((column) => {
                        if (column?.type === 'jsx') {
                          return (
                            <TableCell
                              width={column?.width || 'unset'}
                              key={column?.id || `cell-${groupIndex}-${index}`}
                              className={`${rowClassNames?.(row)}`}
                            >
                              {column?.id && typeof row?.[column.id] === 'function'
                                ? row[column.id](row)
                                : '-'}
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell
                            width={column?.width || 'unset'}
                            key={column?.id || `cell-${groupIndex}-${index}`}
                            className={`${rowClassNames?.(row)}`}
                          >
                            {row?.[column?.id] || '-'}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  )) || (
                    <TableRow>
                      <TableCell colSpan={(columns?.length || 0) + (toSort ? 1 : 0)}>
                        No records in this group.
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ))
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default CommonTable;
